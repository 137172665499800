import React, { useState, useEffect } from 'react';

import { Center, Text, } from 'native-base';

import LoadingIndicator from '../components/LoadingIndicator';

const MyAccountScreen = (props) => {

  // const { navigation } = props;

  // const [loading, setLoading] = useState(true);

  // const [userData, setUserData] = useState({
  //   firstName: '',
  //   lastName: '',
  // });

  // const currentUser = useSelector((state) => state.user.userId);

  // // Load User data
  // useEffect(() => {
  //   return db
  //     .collection('users')
  //     .doc(currentUser)
  //     .onSnapshot((documentSnapshot) => {
  //       const data = documentSnapshot.data();
  //       setUserData(data);
  //       setLoading(false);
  //     });
  // }, [currentUser]);

  // // Remove empty fields
  // const removeEmpty = (obj) => {
  //   // eslint-disable-next-line no-param-reassign
  //   Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  //   return obj;
  // };

  // // Handle Form Submit
  // async function updateMyAccount(values) {
  //   setLoading(true);
  //   await db.collection('users').doc(currentUser).update(values);
  //   setLoading(false);
  //   alert('Changes Saved.');
  // }

  // // ========================================================================
  // // Configure Header

  // useEffect(() => {
  //   navigation.setOptions({
  //     headerTitle: 'My Account',
  //   });
  // }, [navigation, loading]);

  // // END Configure Header
  // // ========================================================================

  // // Show loading spinner
  // if (loading) {
  //   return <LoadingIndicator />;
  // }

  // Render Screen
  return (
    <Center flex="1" padding="3">

      <Text>This screen will eventually contain contact details, user preferences, notification settings, etc.</Text>

      {/* <Formik
        initialValues={{
          firstName: userData.firstName,
          lastName: userData.lastName,
        }}
        onSubmit={(values) => {
          updateMyAccount(removeEmpty(values));
        }}
      >
        {({
          handleChange, handleBlur, handleSubmit, values
        }) => (
          <View style={FormStyles.formWrapper}>
            <Input
              label="First Name"
              style={FormStyles.textInput}
              autoCapitalize="none"
              autoCorrect={false}
              onChangeText={handleChange('firstName')}
              onBlur={handleBlur('firstName')}
              value={values.firstName}
              returnKeyType="next"
            />

            <Input
              label="Last Name"
              style={FormStyles.textInput}
              autoCapitalize="none"
              autoCorrect={false}
              onChangeText={handleChange('lastName')}
              onBlur={handleBlur('lastName')}
              value={values.lastName}
              returnKeyType="next"
            />

            <Button title="Save" onPress={handleSubmit} />
          </View>
        )}
      </Formik> */}


    </Center>
  );
};

export default MyAccountScreen;
