// expo start - starts dev server.
// expo build:web - outputs a deployable set of files to /web-build

import 'react-native-gesture-handler';
import React  from "react";
import { FirebaseAppProvider} from 'reactfire';
import firebaseConfig from './config/firebase';

import AuthWrapper from './screens/AuthWrapper';

import {
  NativeBaseProvider,
  // extendTheme,
} from "native-base";

// ===================================================================================
// Nativebase
// const themeConfig = {
//   useSystemColorMode: false,
//   initialColorMode: "dark",
// };
// export const theme = extendTheme({ themeConfig });
// ===================================================================================

export default function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <NativeBaseProvider>
        <AuthWrapper />
      </NativeBaseProvider>
    </FirebaseAppProvider>
  );
}
