// import Constants from 'expo-constants';

const firebaseConfig = {
  apiKey: 'AIzaSyCQRkhtCREVzBA7ungvV_J9vW-RzsgUjUU',
  authDomain: 'sensorit-v1.firebaseapp.com',
  databaseURL: 'https://sensorit-v1.firebaseio.com',
  projectId: 'sensorit-v1',
  storageBucket: 'sensorit-v1.appspot.com',
  messagingSenderId: '88536897973',
  appId: '1:88536897973:web:b9442aaf115b22e56261fc',
  // apiKey: Constants.manifest.extra.apiKey,
  // authDomain: Constants.manifest.extra.authDomain,
  // databaseURL: Constants.manifest.extra.databaseURL,
  // projectId: Constants.manifest.extra.projectId,
  // storageBucket: Constants.manifest.extra.storageBucket,
  // messagingSenderId: Constants.manifest.extra.messagingSenderId,
  // appId: Constants.manifest.extra.appId,
  // measurementId: Constants.manifest.extra.appId,
};

export default firebaseConfig;