
import React from 'react';
import { Platform } from 'react-native';
import { useFirestore, useUser, useFirestoreDocData } from 'reactfire';
import { doc } from 'firebase/firestore';
import Constants from "expo-constants"
import { useNetInfo } from '@react-native-community/netinfo';
import {
  KeyboardAvoidingView, Container, Center, VStack, HStack, Text,
} from 'native-base';
import LoadingIndicator from './LoadingIndicator';


const ScreenWrapper = (props) => {

    const { children } = props;
    const { isConnected } = useNetInfo();

    // https://www.npmjs.com/package/standard-version-expo
    const version = Constants.manifest.version

    // Load User
    const { status: userStatus, data: user } = useUser();

    const uid = user?.uid || "000";
    const email = user?.email || "000";
  
    // Load User Data
    const { status: userDataStatus, data: userData } = useFirestoreDocData(doc(useFirestore(), 'users', uid));

    // Loading indicator
    if (userStatus === 'loading' || userDataStatus === 'loading' || !uid) {
      return <LoadingIndicator />;
    }

    // Main Render
    return (
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} backgroundColor="white">
            {isConnected ? (
                <Center height="0.5" backgroundColor="green.500"></Center>
            ) : (
                <Center padding="0.5" backgroundColor="red.500"><Text fontSize="xs" color="white" bold>OFFLINE</Text></Center>
            )}
            <Container maxW="100%">
                <VStack w="100%" padding="3" space="3">
                    {children}
                </VStack>
            </Container>
            <Center mb="3">
                {userData?.isSuperAdmin ? (
                    <HStack>
                        <Text fontSize="10px" color="warning.300" bold>ADMIN </Text>
                        <Text fontSize="10px" color="warning.300">{`${uid} - ${email}`}</Text>
                    </HStack>
                ) : (
                    <Text fontSize="10px" color="muted.300">
                        {`${uid} - ${email}`}
                    </Text>
                )}
                <Text fontSize="10px" color="muted.300">V{version}</Text>
            </Center>
        </KeyboardAvoidingView>
    );
};

export default ScreenWrapper;
