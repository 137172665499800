import React, { useState, useEffect } from 'react';
import { Dimensions,StyleSheet } from 'react-native';
import { useFirestore, useUser, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { addDoc, setDoc, updateDoc, deleteDoc, deleteField, collection, doc, query, getDocs } from 'firebase/firestore'; //collectionGroup, where, , orderBy, onSnapshot
// import { CommonActions } from '@react-navigation/native';

import SensorTypes from '../settings/SensorTypes';
// import SensorListColumns from '../settings/SensorListColumns';

import { Formik } from 'formik';
import { format } from 'date-fns';

import { Input, Button, Icon, Text, HStack, VStack, useToast, IconButton, FlatList, Modal, FormControl, View, Pressable, Container
} from 'native-base'; // Center, Box, Flex, Box, ScrollView,Heading, Flex, Center, Divider, 
import { MaterialCommunityIcons } from '@expo/vector-icons'; // MaterialIcons, 

import LoadingIndicator from '../components/LoadingIndicator';
import ScreenWrapper from '../components/ScreenWrapper';

const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

// Main entry 
const FarmDashboardScreen = ({ props, route, navigation }) => {

  // Jump straight into testing sensor
  // navigation.navigate('Sensor History', { accountId: 'Ga3PtafuHpthSrYV1eZM', farmId: 'twJiOWoQhoUTSgBaVbyB', sensorId: '9000-1', sensorName: '', farmSensorId: '8WMuwGCumpIJrWZdCaHC' });

  const { accountId, farmId, userFarmAccessLevel, sensorToDelete=null, sensorReadingsId=null } = route.params;

  // State
  const [loading, setLoading] = useState(true);
  const [editingFarm, setEditingFarm] = useState(false);
  const [addingSensor, setAddingSensor] = useState(false);
  const [sensorIdToAdd, setSensorIdToAdd] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sensorTypeOptions, setSensorTypeOptions] = useState([]);
  // const [sensorListColumns, setSensorListColumns] = useState();
  const [reloadAfterDelete, setReloadAfterDelete] = useState(true);

  // Controls column indexes to hide on mobile of the pseudo table
  // const indexesToHide = [0, 2, 3, 5];

  const toast = useToast();
  const firestore = useFirestore();
  const { status: userStatus, data: user } = useUser();
  // const { uid, email } = user;

  // Load Farm Data
  const farmDoc = doc(firestore, 'accounts/' + accountId + '/farms/', farmId);
  const { status: farmStatus, data: farmData } = useFirestoreDocData(farmDoc, { idField: 'id' });

  // Load Sensors Data
  const sensorCollection = collection(firestore, 'accounts/' + accountId + '/farms/' + farmId + '/sensors');
  const sensorQuery = query(sensorCollection);
  const { status: sensorStatus, data: sensorsData } = useFirestoreCollectionData(sensorQuery, { idField: 'id' });

  // Load Readings Data
  // const readingsCollection = collection(firestore, 'sensors');
  // const readingsQuery = query(readingsCollection, where('attachedToFarm', '==', farmId.toString()));
  // const { status: readingsStatus, data: readingsData } = useFirestoreCollectionData(readingsQuery, { idField: 'id' });

  // Load ALL Readings Data
  const allSensorsCollection = collection(firestore, 'sensors');
  const allSensorsQuery = query(allSensorsCollection);
  const { status: allSensorsStatus, data: allSensorsData } = useFirestoreCollectionData(allSensorsQuery, { idField: 'id' });

  // Dimensions
  const [dimensions, setDimensions] = useState({ window, screen });
  // const dimensionsBreakpoint = 400;
  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen });
  };

  // Dimensions change event capture
  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  // Process field options
  // useEffect(() => {
  //   return db
  //     .collection('accounts')
  //     .doc(currentAccountId)
  //     .collection('farms')
  //     .doc(currentFarmId)
  //     .onSnapshot((documentSnapshot) => {
  //       // Save sensor metadata
  //       const data = documentSnapshot.data();
  //       setFarmData(data);
  //     });
  // }, [currentAccountId, currentFarmId]);

  // Load initial data
  useEffect(() => {
    // Load Sensor List Columns
    // const sensorColumns = [];
    // Object.keys(SensorListColumns).forEach((column) => {
    //   sensorColumns.push({
    //     value: column,
    //     label: SensorListColumns[column].label,
    //     widthStyle: SensorListColumns[column].width === 'fill' ? {
    //       // calc via dimensions?
    //       flex: 1,
    //       alignSelf: 'stretch',
    //       // minWidth: SensorListColumns[column].minWidth
    //     } : { width: SensorListColumns[column].width },
    //     sortable: SensorListColumns[column].sortable,
    //     property: SensorListColumns[column].property,
    //   });
    // });
    // setSensorListColumns(sensorColumns);

    // Load Sensor Type Options
    const sensorTypes = [];
    Object.keys(SensorTypes).forEach((type) => {
      sensorTypes.push({
        value: type,
        label: SensorTypes[type].label,
        readingValue1Unit: SensorTypes[type].readingValue1Unit,
      });
    });
    setSensorTypeOptions(sensorTypes);

    setLoading(false);

  }, [route.params, dimensions, reloadAfterDelete]);

  // Check for deleting a sensor
  useEffect(() => {
    const deleteSensor = async () => {
      await deleteDoc(doc(firestore, "/accounts/" + accountId + '/farms/' + farmId + '/sensors/', sensorToDelete));
      await updateDoc(doc(firestore, "sensors/" + sensorReadingsId), { attachedToAccount: deleteField(), attachedToFarm: deleteField() }, { merge: true });
      return;
    }
    // call the function
    if ( sensorToDelete && sensorReadingsId ) {
      setLoading(true);
      deleteSensor().then(() => {
        toast.show({
          title: `Sensor deleted.`,
          status: 'success',
          placement: 'bottom'
        });
        navigation.setParams({accountId, farmId, userFarmAccessLevel, sensorToDelete: null, sensorReadingsId: null });
        setReloadAfterDelete(true);
        setLoading(false);
      })
      .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
    }
  }, [route.params])

  // ========================================================================
  // Configure Header
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HStack space="3">
          {/* TODO: move edit to farm list screen */}
          {userFarmAccessLevel > 1 ? (
            <HStack space="3">
              <IconButton icon={<Icon as={MaterialCommunityIcons} name="file-edit" color="white" />} onPress={() => setEditingFarm(true)} />
              <IconButton icon={<Icon as={MaterialCommunityIcons} name="plus" color="white" />} onPress={() => setAddingSensor(true)} />
            </HStack>
          ) : null }
        </HStack>
      ),
      headerTitle: farmData?.farmName
    });
  }, [navigation, farmData]);

  // ========================================================================
  // Loading Farm Data
  if (loading || userStatus === 'loading' || farmStatus === 'loading' || sensorStatus === 'loading' || allSensorsStatus === 'loading') {
    return <LoadingIndicator />;
  }

  // /////////////////////////////////////////////////////////////////////////////////////////
  // Via https://stackoverflow.com/questions/2784230/how-do-you-sort-an-array-on-multiple-columns
  Array.prototype.keySort = function (keys) {

    keys = keys || {};

    // via
    // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
    const obLen = function (obj) {
      let size = 0; let
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) { size++; }
      }
      return size;
    };

    // avoiding using Object.keys because I guess did it have IE8 issues?
    // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
    // whatever
    const obIx = function (obj, ix) {
      let size = 0; let
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (size == ix) { return key; }
          size++;
        }
      }
      return false;
    };

    const keySort = function (a, b, d) {
      d = d !== null ? d : 1;
      // a = a.toLowerCase(); // this breaks numbers
      // b = b.toLowerCase();
      if (a == b) { return 0; }
      return a > b ? 1 * d : -1 * d;
    };

    const KL = obLen(keys);

    if (!KL) { return this.sort(keySort); }

    for (const k in keys) {
      // asc unless desc or skip
      keys[k] = keys[k] == 'desc' || keys[k] == -1 ? -1
        : (keys[k] == 'skip' || keys[k] === 0 ? 0
          : 1);
    }

    this.sort((a, b) => {
      let sorted = 0; let
        ix = 0;

      while (sorted === 0 && ix < KL) {
        const k = obIx(keys, ix);
        if (k) {
          const dir = keys[k];
          sorted = keySort(a[k], b[k], dir);
          ix++;
        }
      }
      return sorted;
    });
    return this;
  }


  function getCompleteSortedData() {

    const completeData = [];

    sensorsData.map((sensorData) => {

      // const currentStatus = sensorData.currentStatus;

      // const completeItemData = { ...sensorData, ...currentStatus };

      const completeItemData = { ...sensorData };
      completeData.push(completeItemData);

    });

    const completeSortedData = completeData.keySort({ sensorIgnoring: 'asc', statusSensorCode: 'desc', statusProblemCount: 'desc', sensorType: 'asc', sensorName: 'asc' });
    
    return completeSortedData;
  };

  // =========================================================================================================================
  // Event Handlers

  // ======================================
  // View

  function onPressSensorHandler(accountId, farmId, sensorId, sensorName, farmSensorId, userFarmAccessLevel) {
    const data = { accountId, farmId, sensorId, sensorName, userFarmAccessLevel };
    navigation.navigate('Sensor History', data);
  };

  // ======================================
  // Add

  function onAddSensorHandler() {
    addSensor(sensorIdToAdd);
  }

  async function addSensor(sensorReadingsId) {

    // Check if sensor exists
    const found = allSensorsData.some((el) => el.id === sensorReadingsId);
    if (found) {

      const connectedToFarmId = allSensorsData.filter((obj) => {
        return obj.id === sensorReadingsId;
      });

      if (typeof connectedToFarmId[0].attachedToFarm === 'undefined' || connectedToFarmId[0].attachedToFarm === '') {

        const baseStationId = sensorReadingsId.split('-', 1);

        // Prep data to add to new farm
        const data = {
          sensorReadingsId,
          baseStationId: baseStationId[0],
          attachedToAccount: accountId,
          attachedToFarm: farmId,
        };

        // Add sensor to farm
        await addDoc(collection(firestore, "accounts/" + accountId + '/farms/' + farmId + '/sensors/'), data, { merge: true });

        // Register sensor to farm
        await setDoc(doc(firestore, "sensors/" + sensorReadingsId), { attachedToAccount: accountId, attachedToFarm: farmId }, { merge: true });

        setLoading(false);
        setAddingSensor(false);
        setSensorIdToAdd('');

      } else {
        toast.show({
          title: "This sensor exists but is already attached to a farm. Please try again.",
          status: "error",
          placement: "bottom"
        });
      }

    } else {
      toast.show({
        title: "Sensor does not exist. Please check your ID and try again. Please try again.",
        status: "error",
        placement: "bottom"
      });
    }

  }

  // ======================================
  // Edit

  // Remove empty fields
  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
    return obj;
  };

  // Handle update data
  function onUpdateFarmHandler(values) {
    setEditingFarm(false);
    setLoading(true);
    updateFarm(removeEmpty(values));
    setLoading(false);
  }

  async function updateFarm(values) {
    await setDoc(doc(firestore, "accounts/" + accountId + '/farms/' + farmId), values, { merge: true })
    .then(() => {
      toast.show({
        title: `Changes saved`,
        status: 'success',
        placement: 'bottom'
      });
    })
    .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
  }

  // ==============================
  // Delete Farm

  function onDeleteFarmHandler() {
    setEditingFarm(false);
    setLoading(true);
    deleteFarmSensors()
    .then(() => {
      navigation.navigate('Home', {
        farmIdToDeleteAccountId: accountId,
        farmIdToDelete: farmId
      },
      null,
      Math.random().toString()
      );
    });
  }

  async function deleteFarmSensors() {

    const deleteSensor = async (sensorId, sensorReadingsId) => {
      await deleteDoc(doc(firestore, "/accounts/" + accountId + '/farms/' + farmId + '/sensors/', sensorId));
      await updateDoc(doc(firestore, "/sensors/" + sensorReadingsId), { attachedToAccount: deleteField(), attachedToFarm: deleteField() }, { merge: true });
      return;
    }

    const farmSensorsSnapshot = await getDocs(collection(firestore,  "/accounts/" + accountId + '/farms/' + farmId + '/sensors/'));

    if (!farmSensorsSnapshot.empty) {
      farmSensorsSnapshot.forEach((doc) => {
        const docId = doc.id;
        const { sensorReadingsId } = doc.data();
        deleteSensor(docId, sensorReadingsId);
      });
    }
    
  }

  // ======================================
  // Render Items

  // Sensor List render item
  const renderSensorItem = (itemData) => {

    const { statusSensorCode } = itemData.item;
    const { id } = itemData.item;

    let styleSensorStatus = { textColor: "muted.500", highlightColor: "muted.500", backgroundColor: "muted.300" };

    // Status:
    // 0 - Grey     Sensor being ignored.
    // 1 - Green    All good.
    // 2 - Orange   1 or 2 readings have been missed.
    // 3 - Red      Above/below limits, 3 or more readings missed, voltage below limit

    // TODO: fix the application of these colors from Nativebase theme. muted.500 etc don't seem to work.

    if (itemData.item.sensorIgnoring || typeof itemData.item.sensorType === 'undefined') {
      styleSensorStatus = { textColor: "#bababa", highlightColor: "#bababa", backgroundColor: "#f2f2f2" };
    } else if (statusSensorCode === 3) {
      styleSensorStatus = { textColor: "black", highlightColor: "red", backgroundColor: '#f2d7d5' };
    } else if (statusSensorCode === 2) {
      styleSensorStatus = { textColor: "black", highlightColor: "orange", backgroundColor: "transparent" };
    } else if (!statusSensorCode || statusSensorCode === 0 || statusSensorCode === 1) {
      styleSensorStatus = { textColor: "black", highlightColor: "green", backgroundColor: "transparent" };
    }

    let batteryColor;

    if (itemData.item.sensorIgnoring) {
      batteryColor = "#bababa";
    } else {
      batteryColor = itemData.item.latestVoltage > 2.7 ? "green" : "red";
    }

    function getSensorReadingDisplayValue(data) {
      const {
        sensorType, sensorDisplayOption, latestValue1, latestTankVolume, latestTankVolumePercentage
      } = data;
      let value;

      if (typeof sensorType !== 'undefined' && sensorType !== 'relay') {

        switch (sensorType) {

          case 'tank':
          case 'fuel':
            switch (sensorDisplayOption) {
              case 'mm':
                value = `${Math.round(latestValue1)} ${sensorDisplayOption}`;
                break;
              case 'L':
                value = `${Math.round(latestTankVolume)} ${sensorDisplayOption}`;
                break;
              case '%':
                value = `${Math.round(latestTankVolumePercentage)} ${sensorDisplayOption}`;
                break;
            }
            break;

          case 'gate':
            switch (latestValue1) {
              case 0:
                value = 'Open';
                break;
              case 1:
                value = 'Closed';
                break;              
            }
            break;

          default:
            value = itemData.item.latestValue1 ? `${itemData.item.latestValue1} ${sensorTypeOptions.find((o) => { return o.value === itemData.item.sensorType; }).readingValue1Unit}` : '-';
            break;

        }

      }
      return value;
    }
   
    if (dimensions.window.width > 800) {
      // Render row for large screen
      return (
        <Pressable onPress={() => onPressSensorHandler(itemData.item?.attachedToAccount || accountId, itemData.item?.attachedToFarm || farmId, itemData.item.id, itemData.item.sensorName, itemData.item.farmSensorId, userFarmAccessLevel)}>
          <HStack borderBottomWidth={2} borderBottomColor="muted.200" padding="2" style={{ width: "100%", flex: 1, alignItems: "center" }} space="3">
            <HStack padding="2" style={{ flexGrow: 1, alignItems: "center" }} space="3" >
              <Text fontWeight="bold" style={{ color: styleSensorStatus.textColor || 'black', width: 160 }}>
                {
                  itemData.item.sensorName || (
                    <Text style={{ color: '#7f7f7f', fontStyle: 'italic' }}>Name not set...</Text>
                  )
                }
              </Text>
              <Text fontWeight="bold" style={{ color: styleSensorStatus.textColor || 'black', width: 160, backgroundColor: "#EEEEEE", fontSize: 15 }}>
                {getSensorReadingDisplayValue(itemData.item)}
                <Text fontWeight="normal" style={{ color: styleSensorStatus.textColor || 'black', fontSize: 12, fontStyle: 'italic' }}>
                  {statusSensorCode !== 0 && typeof itemData.item.latestReadingTime !== 'undefined' ? (
                    <Text>{format(new Date(itemData.item.latestReadingTime.toDate()), ' d/M/yy hh:mm a')}</Text>
                  ) : null}
                </Text>
              </Text>
              <Text style={{ color: '#7F7F7F', fontSize: 10, fontStyle: 'italic', width: 60 }}>
                {itemData.item.sensorReadingsId + ' '} 
                {typeof itemData.item.sensorType !== 'undefined' ? sensorTypeOptions.find((o) => { return o.value === itemData.item.sensorType; }).label : null}
              </Text>
              <Text style={{ margin: 7, color: styleSensorStatus.textColor || 'black' }}>
                {!itemData.item.sensorIgnoring ? (itemData.item.statusProblemDescriptionString) : ''}
              </Text>
            </HStack>
            <Container style={{ width: 36 }}>
              <Container
                  style={{
                    margin: 7,
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: batteryColor,
                  }}>
                  <Icon as={MaterialCommunityIcons} name={itemData.item.latestVoltage > 2.7 ? "battery" : "battery-20"} color={"white"} />
              </Container>
            </Container>
            <Container style={{ width: 36 }}>
              <Container
                  style={{
                    margin: 7,
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: styleSensorStatus.highlightColor,
                  }}>
                  {itemData.item?.statusProblemCount ? (                    
                    <Text style={{ color: "white", fontSize: 12, fontWeight: 'bold', }}>{itemData.item?.statusProblemCount}</Text>
                  ) : (
                    <Icon as={MaterialCommunityIcons} name="chevron-right" color="white" />
                  )}
              </Container>
            </Container>
          </HStack>
        </Pressable>
      );
    } else {
      // Render for mobile
      return (
        <Pressable onPress={() => onPressSensorHandler(itemData.item?.attachedToAccount || accountId, itemData.item?.attachedToFarm || farmId, itemData.item.id, itemData.item.sensorName, itemData.item.farmSensorId, userFarmAccessLevel)}>
          <HStack borderBottomWidth={2} borderBottomColor="muted.200" padding="2" style={{ width: "100%", flex: 1, alignItems: "center" }} space="3">
            <VStack style={{  flexGrow: 1 }}>
              <Text fontWeight="bold" style={{ color: styleSensorStatus.textColor || 'black' }}>
                {
                  itemData.item.sensorName || (
                    <Text style={{ color: '#7f7f7f', fontStyle: 'italic' }}>Name not set...</Text>
                  )
                }
              </Text>
              <Text style={{ color: '#7F7F7F', fontSize: 9, fontStyle: 'italic', marginLeft: 6 }}>
                {itemData.item.sensorReadingsId + ' '} 
                {typeof itemData.item.sensorType !== 'undefined' ? sensorTypeOptions.find((o) => { return o.value === itemData.item.sensorType; }).label : null}
              </Text>
              <Text fontWeight="bold" style={{ color: styleSensorStatus.textColor || 'black', width: 170, backgroundColor: "#EEEEEE", fontSize: 14, marginLeft: 6 }}>
                {getSensorReadingDisplayValue(itemData.item)}
                <Text fontWeight="normal" style={{ color: styleSensorStatus.textColor || 'black', fontSize: 11, fontStyle: 'italic' }}>
                  {statusSensorCode !== 0 && typeof itemData.item.latestReadingTime !== 'undefined' ? (
                    <Text>{format(new Date(itemData.item.latestReadingTime.toDate()), ' d/M/yy hh:mm a')}</Text>
                  ) : null}
                </Text>
              </Text>
              <Text style={{ color: styleSensorStatus.textColor || 'black', marginLeft: 6 }}>
                {!itemData.item.sensorIgnoring ? (itemData.item.statusProblemDescriptionString) : ''}
              </Text>
            </VStack>
            <Container style={{ width: 36 }}>
              <Container
                  style={{
                    margin: 7,
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: batteryColor,
                  }}>
                  <Icon as={MaterialCommunityIcons} name={itemData.item.latestVoltage > 2.7 ? "battery" : "battery-20"} color={"white"} />
              </Container>
            </Container>
            <Container style={{ width: 36 }}>
              <Container
                  style={{
                    margin: 7,
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: styleSensorStatus.highlightColor,
                  }}>
                  {itemData.item?.statusProblemCount ? (                    
                    <Text style={{ color: "white", fontSize: 12, fontWeight: 'bold', }}>{itemData.item?.statusProblemCount}</Text>
                  ) : (
                    <Icon as={MaterialCommunityIcons} name="chevron-right" color="white" />
                  )}
              </Container>
            </Container>
          </HStack>
        </Pressable>
      );
    }
  };

  // const headerCellComponent = (column, index) => {
  //   return (column.label
  //     ? (
  //       column.sortable ? (
  //         <TouchableOpacity style={{ ...styles.tableHeaderCell, ...column.widthStyle }} onPress={() => { alert('Sort Pressed'); }}>
  //           <Text>{column.label}</Text>
  //           <Icon
  //             style={styles.tableHeaderSortIcon}
  //             name="sort"
  //             type="font-awesome"
  //             iconRight
  //             size={10}
  //           />
  //         </TouchableOpacity>
  //       ) : (<View style={{ ...styles.tableHeaderCell, ...column.widthStyle }}><Text>{column.label}</Text></View>)
  //     ) : (<View style={{ ...styles.tableHeaderCell, ...column.widthStyle }} />));
  // };


  // ===============================================================================================
  // Main Render
  return (
    <ScreenWrapper>

      {/* {farmData.description ? <><Text>{farmData.description}</Text><Divider /></> : null} */}

      {sensorsData && sensorsData.length === 0 ? (
        <Text>This farm doesn&apos;t have any sensors.</Text>
      ) : (
        <FlatList
          style={{ width: "100%", flex: 1 }}
          data={getCompleteSortedData()}
          renderItem={renderSensorItem}
        />
      )}

      {/* Adding Sensor Modal */}

      <Modal isOpen={addingSensor} onClose={() => setAddingSensor(false)}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>Add Sensor</Modal.Header>
          <Modal.Body>
            <VStack space="2">
              <Text>Enter the ID of the sensor you wish to add to your account below.</Text>
              <Input
                placeholder="Sensor ID"
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                value={sensorIdToAdd}
                onChangeText={(value) => {
                  setSensorIdToAdd(value);
                }}
              />
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" onPress={() => setAddingSensor(false)}>Cancel</Button>
              <Button onPress={() => onAddSensorHandler()}>Add</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>


      {/* Editing Farm Modal */}

      <Modal isOpen={editingFarm} onClose={() => setEditingFarm(false)}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>{"Edit " + farmData?.farmName}</Modal.Header>
          <Formik
            enableReinitialize
            initialValues={{
              farmName: farmData?.farmName || "",
            }}
            onSubmit={(values) => {
              onUpdateFarmHandler(values);
            }}
          >
            {({
              values, handleChange, handleBlur, handleSubmit,
            }) => (
              <>
                <Modal.Body>

                  <VStack space="3">

                    <FormControl space="1">
                      <FormControl.Label>Farm Name</FormControl.Label>
                      <Input
                        onChangeText={handleChange('farmName')}
                        onBlur={handleBlur('farmName')}
                        value={values.farmName}
                        returnKeyType="next" />
                    </FormControl>

                  </VStack>

                </Modal.Body>
                <Modal.Footer>
                  <HStack space={2} style={{ width: "100%", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>

                    {confirmDelete ? (
                      <Button variant="ghost" onPress={() => setConfirmDelete(false)}>Cancel Delete</Button>
                    ) : (
                      <>
                        {userFarmAccessLevel >= 3 ? (
                          <Button colorScheme="danger" onPress={() => setConfirmDelete(true)}>Delete</Button>
                        ) : <Button.Group></Button.Group> }
                      </>
                    )}

                    {!confirmDelete ? (
                      <Button.Group space={2}>
                        <Button variant="ghost" onPress={() => { setEditingFarm(false); setConfirmDelete(false); }}>Cancel</Button>
                        <Button onPress={() => handleSubmit()}>Save</Button>
                      </Button.Group>
                    ) : <Button colorScheme="danger" onPress={() => onDeleteFarmHandler()}>Confirm Delete</Button>}

                  </HStack>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>

    </ScreenWrapper>

  );
};

// const styles = StyleSheet.create({
//   tableHeaderWrapper: {
//     // flex: 1,
//     width: '100%',
//   },
//   tableHeaderRow: {
//     flexDirection: 'row',
//     marginBottom: 10,
//     marginLeft: 7,
//   },
//   tableHeaderCell: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     margin: 7,
//   },
//   tableHeaderSortIcon: {
//     marginLeft: 5,
//   },
//   tablePadding: {
//     marginVertical: 5,
//     marginHorizontal: 10,
//   },
//   tableBodyWrapper: {
//     // flex: 1,
//     width: '100%',
//   },
//   tableBodyRow: {
//     flexDirection: 'row',
//     borderTopColor: '#d8d8d8',
//     borderTopWidth: 1,
//   },
//   tableBodyCell: {
//     alignItems: 'flex-start',
//     margin: 5,
//   },
//   problemIndicator: {
//     width: 20,
//     height: 20,
//     borderRadius: 10,
//     alignItems: 'center',
//     justifyContent: 'center',
//   }
// });

export default FarmDashboardScreen;
