// Use setDoc and { merge: true } for all kinds of data update

import React, { useState, useEffect } from 'react'; // , useContext
import { useFirestore, useUser, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { setDoc, addDoc, updateDoc, deleteDoc, collectionGroup, collection, doc, query, where, getDocs, orderBy } from 'firebase/firestore'; // , Timestamp 
import { FlatList } from 'react-native'; // , ScrollView View, 
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { VStack, HStack, FormControl, Heading, Text, Divider, Modal, Button, Select, Input, useToast, IconButton, Icon, Pressable,
} from 'native-base'; // Checkbox, Box, Center, , SectionList, ListItem

import { Formik, FieldArray } from 'formik'; // , useFormik, FormikProvider
import ScreenWrapper from '../components/ScreenWrapper';
import LoadingIndicator from '../components/LoadingIndicator';

// Main entry function
const FarmListScreen = ({ route, navigation }) => { //  props,
  
  const { farmIdToDeleteAccountId, farmIdToDelete } = route.params;

  // State
  const [loading, setLoading] = useState(false);
  const [addingEditingAccount, setAddingEditingAccount] = useState(false);
  const [addingFarm, setAddingFarm] = useState(false);
  const [farmToAdd, setFarmToAdd] = useState({ farmName: '', attachedToAccount: '' });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showSharingAccount, setShowSharingAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [selectedAccountFarmSharingOptions, setSelectedAccountFarmSharingOptions] = useState([]);
  // const [selectedFarm, setSelectedFarm] = useState(false);
  // const [userToShare, setUserToShare] = useState({email: '', level: '', farmSharing: [] });

  // Toast
  const toast = useToast();

  // Load Firestore
  const firestore = useFirestore();

  // Load User
  const { status: userStatus, data: user } = useUser();
  // const { uid, email } = user;

  const uid = user?.uid || "000";
  // const email = user?.email || "000";

  // Load User Data
  const { status: userDataStatus, data: userData } = useFirestoreDocData(doc(useFirestore(), 'users', uid));

  // Load Accounts
  const accountCollection = collection(firestore, 'accounts');
  const userAccountQuery = query(accountCollection, orderBy('accountName', 'asc'));
  // const userAccountQuery = query(accountCollection, where('sharing', 'array-contains', email || 0));
  const { status: accountStatus, data: accountData } = useFirestoreCollectionData(userAccountQuery, { idField: 'id' });

  // Load Farms
  const farmCollection = collectionGroup(firestore, 'farms');
  const userFarmQuery = query(farmCollection, orderBy('farmName', 'asc'));
  // const userFarmQuery = query(accountCollection, where('sharing', 'array-contains', email || 0));
  const { status: farmStatus, data: farmData } = useFirestoreCollectionData(userFarmQuery, { idField: 'id' });

  // Load farm options for sharing form
  useEffect(() => {
    if (selectedAccount !== false && farmStatus !== 'loading') {
      const accountFarmData = farmData.filter(function (farm) {
        return farm.attachedToAccount === selectedAccount.id;
      });
      const options = [];
      accountFarmData.forEach((farm) => {
        options.push({
          id: farm.id,
          farmName: farm.farmName,
          level: null,
        });
      });
      setSelectedAccountFarmSharingOptions(options);
    }
  }, [selectedAccount, farmData]);

  // Check for deleting a farm
  useEffect(() => {
   
    const deleteFarm = async () => {
      await deleteDoc(doc(firestore, "/accounts/" + farmIdToDeleteAccountId + '/farms/', farmIdToDelete));
      return;
    }

    // call the function
    if ( farmIdToDeleteAccountId !== '' && farmIdToDelete !== '' ) {
      setLoading(true);
      deleteFarm().then(() => {
        toast.show({
          title: `Farm deleted.`,
          status: 'success',
          placement: 'bottom'
        });
        setLoading(false);
      })
      .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
    }

  }, [route.params])

  // ========================================================================
  // Configure Header
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HStack space="3">          
          <IconButton icon={<Icon as={MaterialCommunityIcons} name="menu" color="white" />} onPress={() => navigation.toggleDrawer()} />
        </HStack>
      ),
      headerRight: () => (
        <HStack space="3">
          <IconButton icon={<Icon as={MaterialCommunityIcons} name="plus" color="white" />} onPress={() => { setSelectedAccount(false); setAddingEditingAccount(true); }} />
        </HStack>
      )
    });
  }, [navigation]);

  
  // ========================================================================
  // Loading Account Data
  if (loading || userStatus === 'loading' || userDataStatus === 'loading' || accountStatus === 'loading' || farmStatus === 'loading') {
    return <LoadingIndicator />;
  }

  // ========================================================================
  // Sorting
  Array.prototype.keySort = function (keys) {

    keys = keys || {};

    // via
    // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
    const obLen = function (obj) {
      let size = 0; let
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) { size++; }
      }
      return size;
    };

    // avoiding using Object.keys because I guess did it have IE8 issues?
    // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
    // whatever
    const obIx = function (obj, ix) {
      let size = 0; let
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (size == ix) { return key; }
          size++;
        }
      }
      return false;
    };

    const keySort = function (a, b, d) {
      d = d !== null ? d : 1;
      // a = a.toLowerCase(); // this breaks numbers
      // b = b.toLowerCase();
      if (a == b) { return 0; }
      return a > b ? 1 * d : -1 * d;
    };

    const KL = obLen(keys);

    if (!KL) { return this.sort(keySort); }

    for (const k in keys) {
      // asc unless desc or skip
      keys[k] = keys[k] == 'desc' || keys[k] == -1 ? -1
        : (keys[k] == 'skip' || keys[k] === 0 ? 0
          : 1);
    }

    this.sort((a, b) => {
      let sorted = 0; let
        ix = 0;

      while (sorted === 0 && ix < KL) {
        const k = obIx(keys, ix);
        if (k) {
          const dir = keys[k];
          sorted = keySort(a[k], b[k], dir);
          ix++;
        }
      }
      return sorted;
    });
    return this;
  }

  // ========================================================================
  // Lookups

  const getAccountFarms = id => {
    const accountFarmData = farmData.filter(farm => farm.attachedToAccount === id );
    return accountFarmData;
  }

  // ========================================================================
  // Handlers

  // ======================================
  // View

  function onPressFarmHandler(accountId, farmId, userFarmAccessLevel) {
    navigation.navigate('Farm Dashboard', { accountId, farmId, userFarmAccessLevel });
  };

  // ======================================
  // Add

  // Account
  function onAddAccountHandler(values) {
    setAddingEditingAccount(false);
    setLoading(true);
    // TODO: Owner should probably be moved to Formik initialValues
    const updatedValues = {...values, ...{owner: uid}};
    addAccount(removeEmpty(updatedValues));
    setLoading(false);
  }

  async function addAccount(values) {
    await addDoc(collection(firestore, "accounts/"), values)
    // .then(async(account) => {
    //   const userAccountsArray = accountAccess || [];
    //   const updatedUserAccounts = [...userAccountsArray, account.id];
    //   await setDoc(doc(firestore, "users/" + uid), {accountAccess: updatedUserAccounts}, { merge: true }).then(() => {
    //     toast.show({
    //       title: "Account added",
    //       status: "success",
    //       placement: "bottom"
    //     });
    //   });
    // })
    .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
  }

  // Farm
  function onAddFarmHandler() {
    setAddingFarm(false);
    setLoading(true);
    addFarm(farmToAdd);
    setFarmToAdd('');
    setLoading(false);
  }

  async function addFarm() {
    const { farmName, attachedToAccount } = farmToAdd;
    await addDoc(collection(firestore, "accounts/" + attachedToAccount + "/farms"), farmToAdd).then(() => {
      toast.show({
        title: farmName + " added",
        status: "success",
        placement: "bottom"
      });
      setFarmToAdd({ farmName: '', attachedToAccount: '' });
    });
  };

  // ======================================
  // Edit

  // Remove empty fields
  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
    return obj;
  };

  // Handle update data
  function onUpdateAccountHandler(values) {
    setAddingEditingAccount(false);
    setLoading(true);
    updateAccount(removeEmpty(values));
    setLoading(false);
  }

  async function updateAccount(values) {
    await setDoc(doc(firestore, "accounts/" + selectedAccount.id), values, { merge: true }).then(() => {
      toast.show({
        title: `Changes saved`,
        status: 'success',
        placement: 'bottom'
      });
    })
    .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
  }

  // ======================================
  // Handle share account

  // async function getUserFromUid(uid) {
  //   let userData = false;
  //   const allUsersSnapshot = await getDocs(query(collection(firestore, "users"), where("id", "==", uid)));
  //   allUsersSnapshot.forEach((user) => {
  //     userData = user.data();
  //   });
  //   return userData;
  // };  

  async function getUidFromEmail(email) {
    let uid = false;
    const allUsersSnapshot = await getDocs(query(collection(firestore, "users"), where("email", "==", email)));
    allUsersSnapshot.forEach((user) => {
      uid = user.id;
    });
    return uid;
  };

  function onShareAccountHandler(values) {

    // TODO: block admins from deleting each other
    // TODO: add owner to list
    // TODO: sort list by access level then name

    const updatedEmail = values.email.toLowerCase();
    const updatedValues = {...values, ...{email: updatedEmail}};

    getUidFromEmail(updatedValues.email).then((uid) => {
      if (uid !== false) {
        if (selectedAccount?.sharing && selectedAccount.sharing.some((e) => e.email === updatedValues.email)) {
          toast.show({
            title: 'Sorry, account already shared with that user',
            status: 'error',
            placement: 'bottom'
          });
        } else {
          let updatedSharing;
          const newUserShare = {...updatedValues, ...{uid}};
          if (selectedAccount.sharing) {
            updatedSharing = selectedAccount.sharing;
            // updatedSharing[uid] = newUserShare; // Maybe try this at some stage
            updatedSharing.push(newUserShare);
          } else {
            updatedSharing = [];
            // updatedSharing[uid] = newUserShare; // Maybe try this at some stage
            updatedSharing.push(newUserShare);
          }
          updateAccountSharing(updatedSharing, 'added');
        }
      } else {
        toast.show({
          title: 'Sorry, no account exists with that email.',
          status: 'error',
          placement: 'bottom'
        });
      }
    });

  }

  async function updateAccountSharing(updatedSharing) {
    await setDoc(doc(firestore, "accounts/" + selectedAccount.id), { sharing: updatedSharing }, { merge: true }).then(() => {
      toast.show({
        title: `Sharing successfully updated`,
        status: 'success',
        placement: 'bottom'
      });
    })
    .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
  };

  // ======================================
  // Delete

  function onDeleteAccountHandler() {
    setAddingEditingAccount(false);
    setSelectedAccount(false);
    setConfirmDelete(false);
    setLoading(true);
    deleteAccount(selectedAccount.id).then(() => {
      setLoading(false);
      toast.show({
        title: `Account deleted`,
        status: 'success',
        placement: 'bottom'
      });
    });
  }

  async function deleteAccount(selectedAccountId) {

    const deleteAccount = async () => {
      await deleteDoc(doc(firestore, "/accounts/", selectedAccountId));
      return;
    }

    const resetSensor = async (sensorId) => {
      await updateDoc(doc(firestore, "/sensors/" + sensorId), { attachedToAccount: deleteField(), attachedToFarm: deleteField() }, { merge: true });
      return;
    }

    const resetSensors = async () => {
      const accountSensorsSnapshotQuery = query(collection(firestore, '/sensors/'), where("attachedToAccount", "==", selectedAccountId));
      const accountSensorsSnapshot = await getDocs(accountSensorsSnapshotQuery);
      if (!accountSensorsSnapshot.empty) {
        accountSensorsSnapshot.forEach((doc) => {
          var sensorId = doc.id;
          resetSensor(sensorId);
        });
      }
    }

    resetSensors()
    .then(() => {
      deleteAccount().then(() => {
        toast.show({
          title: "Account deleted",
          status: "success",
          placement: "bottom"
        });
      });
    });

  }

  // Delete sharing permissions for a user
  function onDeleteUserShareHandler(index) {
    const sharingArray = selectedAccount.sharing;
    sharingArray.splice(index, 1);
    updateAccountSharing(sharingArray, 'removed');
  }

  // ======================================
  // Render Items

  const renderFarmItem = ({item}) => {
    return (
      <Pressable onPress={() => onPressFarmHandler(item.farm.attachedToAccount, item.farm.id, item.userFarmAccessLevel)}>
        <HStack borderTopWidth={1} borderTopColor="muted.200" style={{ width: "100%", flex: 1, alignItems: "center" }} space="3">
          <Text style={{ flexGrow: 1 }}>{item.farm.farmName}</Text>
          <HStack space="1">
            <IconButton icon={<Icon as={MaterialCommunityIcons} name="chevron-right" />} onPress={() => onPressFarmHandler(item.farm.attachedToAccount, item.farm.id, item.userFarmAccessLevel)} />
          </HStack>
        </HStack>
      </Pressable>
    )
  };

  // Account List render item
  const renderAccountItem = ({ item }) => {

    // Does user have access to this farm?
    const sharing = item?.sharing || [];

    const currentUserAccess = sharing.find(obj => {
      return obj.uid == uid;
    })

    let userAccountAccessLevel;

    if (userData?.isSuperAdmin) {
      userAccountAccessLevel = 10;
    } else {
      userAccountAccessLevel = currentUserAccess ? parseInt(currentUserAccess.accountLevel) : false;
    }

    // Completely block all access to whole account
    if ( uid !== item.owner && userAccountAccessLevel === false ) {
      return;
    }

    const accountFarmData = getAccountFarms(item.id);

    // process each farm into a new array
    const farms = [];
    accountFarmData.forEach(farm => {
      let userFarmAccessLevel;

      const farmSettings = currentUserAccess?.farmSharing.find(obj => {
        return obj.id == farm.id;
      })

      if (userData?.isSuperAdmin) {
        userFarmAccessLevel = 10;
      } else {
        if (uid === item.owner) {
          userFarmAccessLevel = 5;
        } else {
          userFarmAccessLevel = farmSettings ? parseInt(farmSettings.level) : false;
        }
      }

      // Completely block all access to whole farm
      if ( uid !== item.owner && ( userFarmAccessLevel === false || userFarmAccessLevel === 0 ) ) {
        return;
      }
      farms.push({
        userFarmAccessLevel,
        farm
      })
    })

    return (
      <VStack borderBottomWidth={2} borderBottomColor="muted.200">
        <Pressable onPress={() => { setSelectedAccount(item); setShowAccountDetails(true); }} >
          <HStack style={{ width: "100%", flex: 1, alignItems: "center" }} space="3">
            <Heading py="3" size="sm" style={{ flexGrow: 1 }}>{item.accountName}</Heading>
            <HStack space="1">

              {uid === item.owner || userAccountAccessLevel >= 3 ? (
                <IconButton icon={<Icon as={MaterialIcons} name="add" />} onPress={() => { setSelectedAccount(item); setAddingFarm(true); }} />
              ) : null}
              
              {uid === item.owner || userAccountAccessLevel >= 3  ? (
                <IconButton icon={<Icon as={MaterialIcons} name="share" />} onPress={() => { setSelectedAccount(item); setShowSharingAccount(true); }} />
              ) : null}

              {uid === item.owner || userAccountAccessLevel >= 2  ? (
                <IconButton icon={<Icon as={MaterialIcons} name="edit" />} onPress={() => { setSelectedAccount(item); setAddingEditingAccount(true); }} />
              ) : null}

            </HStack>
          </HStack>
        </Pressable>
        <FlatList test="data" data={farms} keyExtractor={item => item.farm.id} renderItem={renderFarmItem} 
            ListEmptyComponent={<Text pb="2" color="muted.400" fontSize="xs" fontStyle="italic">No farms...</Text>} />
      </VStack>
    );
  };



  // User sharing list render item
  const getAccessLevelLabel = (accessLevel) => {
    if (accessLevel == 3) {
      return "Administrator";
    } else if (accessLevel == 2) {
      return "Editor";
    } else if (accessLevel == 1) {
      return "Viewer";
    }
    return "No Access";
  };

  const farmAndLevel = (item) => {
    if (item.item.level && item.item.level != 0) {
      return (
        <HStack space="1">
          <Text fontSize="xs" bold>{item.item.farmName}:</Text>
          <Text fontSize="xs">{getAccessLevelLabel(item.item.level)}</Text>
        </HStack>
      );
    }
  }

  const userShareItem = (itemData) => {
    return (
      <VStack space="3">
        <Divider />
        <HStack space="3">
          <VStack space="1" style={{ flexGrow: 1 }}>
            <HStack space="3">
              <Text bold >{itemData.item.email}</Text>
              <Text>Account {getAccessLevelLabel(itemData.item.accountLevel)}</Text>
            </HStack>
            <FlatList
              data={itemData.item.farmSharing}
              renderItem={farmAndLevel}
              keyExtractor={(item, index) => `${index}-${item.id}`}
            />
          </VStack>
          <VStack>
            <IconButton icon={<Icon as={MaterialIcons} name="delete" />} onPress={() => onDeleteUserShareHandler(itemData.index)} />
          </VStack>
        </HStack>
      </VStack>
    );
  };

  // ============================================================================
  // Main render
  return (    
    <ScreenWrapper>
      {accountData && accountData.length === 0 ? (
        <Text>You don't have access to any accounts.</Text>
      ) : (
        <FlatList
          style={{ width: "100%", flex: 1 }}
          data={accountData}
          renderItem={renderAccountItem} />
      )}

      <Modal isOpen={addingEditingAccount} onClose={() => { setAddingEditingAccount(false); setSelectedAccount(false); }}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>{selectedAccount ? "Edit " + selectedAccount.accountName : "Add Account"}</Modal.Header>
          <Formik
            key="addingEditingAccount"
            enableReinitialize
            initialValues={{
              accountName: selectedAccount?.accountName || "",
              contactName: selectedAccount?.contactName || "",
              contactEmail: selectedAccount?.contactEmail || "",
              contactPhone: selectedAccount?.contactPhone || "",
              addressLine1: selectedAccount?.addressLine1 || "",
              addressLine2: selectedAccount?.addressLine2 || "",
              addressCity: selectedAccount?.addressCity || "",
              addressPostcode: selectedAccount?.addressPostcode || "",
              addressCountry: selectedAccount?.addressCountry || "",
            }}
            onSubmit={(values) => {
              if (selectedAccount) {
                onUpdateAccountHandler(values);
              } else {
                onAddAccountHandler(values);
              }
            }}
          >
            {({
              values, handleChange, handleBlur, handleSubmit,
            }) => (
              <>
                <Modal.Body>

                  {/* TODO: add ownership transfer */}

                  <VStack space="3">

                    <FormControl space="1">
                      <FormControl.Label>Account Name</FormControl.Label>
                      <Input
                        onChangeText={handleChange('accountName')}
                        onBlur={handleBlur('accountName')}
                        value={values.accountName}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Contact Name</FormControl.Label>
                      <Input
                        onChangeText={handleChange('contactName')}
                        onBlur={handleBlur('contactName')}
                        value={values.contactName}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Contact Email</FormControl.Label>
                      <Input
                        onChangeText={handleChange('contactEmail')}
                        onBlur={handleBlur('contactEmail')}
                        value={values.contactEmail}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Contact Phone</FormControl.Label>
                      <Input
                        onChangeText={handleChange('contactPhone')}
                        onBlur={handleBlur('contactPhone')}
                        value={values.contactPhone}
                        returnKeyType="next" />
                    </FormControl>

                    <Divider />

                    <FormControl space="1">
                      <FormControl.Label>Address</FormControl.Label>
                      <Input
                        onChangeText={handleChange('addressLine1')}
                        onBlur={handleBlur('addressLine1')}
                        value={values.addressLine1}
                        returnKeyType="next" />
                      <Input
                        onChangeText={handleChange('addressLine2')}
                        onBlur={handleBlur('addressLine2')}
                        value={values.addressLine2}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>City</FormControl.Label>
                      <Input
                        onChangeText={handleChange('addressCity')}
                        onBlur={handleBlur('addressCity')}
                        value={values.addressCity}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Postcode</FormControl.Label>
                      <Input
                        onChangeText={handleChange('addressPostcode')}
                        onBlur={handleBlur('addressPostcode')}
                        value={values.addressPostcode}
                        returnKeyType="next" />
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Country</FormControl.Label>
                      <Input
                        onChangeText={handleChange('addressCountry')}
                        onBlur={handleBlur('addressCountry')}
                        value={values.addressCountry}
                        returnKeyType="next" />
                    </FormControl>

                  </VStack>

                </Modal.Body>
                <Modal.Footer>
                  <HStack space={2} style={{ width: "100%", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>

                    {confirmDelete ? (
                      <Button variant="ghost" onPress={() => setConfirmDelete(false)}>Cancel Delete</Button>
                    ) : (
                      <>
                        {uid === selectedAccount.owner || userData?.isSuperAdmin ? (
                          <Button colorScheme="danger" onPress={() => setConfirmDelete(true)}>Delete</Button>
                        ) : <Button.Group></Button.Group> }
                      </>
                    )}

                    {!confirmDelete ? (
                      <Button.Group space={2}>
                        <Button variant="ghost" onPress={() => { setAddingEditingAccount(false); setConfirmDelete(false); }}>Cancel</Button>
                        <Button onPress={() => handleSubmit()}>Save</Button>
                      </Button.Group>
                    ) : <Button colorScheme="danger" onPress={() => onDeleteAccountHandler()}>Confirm Delete</Button>}

                  </HStack>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>

      {/* ========================================================== */}
      {/* Details Modal */}

      <Modal isOpen={showAccountDetails} onClose={() => { setShowAccountDetails(false); setSelectedAccount(false); }}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>{"Details - " + selectedAccount?.accountName}</Modal.Header>
          <Modal.Body>
            <VStack space="2">

              <HStack>
                <Text w="50%">Contact Name</Text>
                <Text bold w="50%">{selectedAccount?.contactName}</Text>
              </HStack>

              <HStack>
                <Text w="50%">Contact Email</Text>
                <Text bold w="50%">{selectedAccount?.contactEmail}</Text>
              </HStack>

              <HStack>
                <Text w="50%">Contact Phone</Text>
                <Text bold w="50%">{selectedAccount?.contactPhone}</Text>
              </HStack>

              <Divider />

              <HStack>
                <Text w="50%">Address:</Text>
                <Text bold w="50%">{selectedAccount?.addressLine1}</Text>
              </HStack>

              {selectedAccount?.addressLine2 ? (
                <HStack>
                  <Text w="50%"></Text>
                  <Text bold w="50%">{selectedAccount?.addressLine2}</Text>
                </HStack>
              ) : null}

              <HStack>
                <Text w="50%">City</Text>
                <Text bold w="50%">{selectedAccount?.addressCity}</Text>
              </HStack>

              <HStack>
                <Text w="50%">Postcode</Text>
                <Text bold w="50%">{selectedAccount?.addressPostcode}</Text>
              </HStack>

              <HStack>
                <Text w="50%">Country</Text>
                <Text bold w="50%">{selectedAccount?.addressCountry}</Text>
              </HStack>

              {/* <Divider />
              <Text>{JSON.stringify(selectedAccount)}</Text> */}

            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>

      {/* ========================================================== */}
      {/* Add Farm Modal */}

      <Modal isOpen={addingFarm} onClose={() => setAddingFarm(false)}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>{"Add a farm - " + selectedAccount?.accountName}</Modal.Header>
          <Modal.Body>
            <VStack space="2">
              <FormControl space="1">
                <FormControl.Label>Farm Name</FormControl.Label>
                <Input returnKeyType="done" placeholder="Enter farm name..." value={farmToAdd.name} onChangeText={(value) => setFarmToAdd({ farmName: value, attachedToAccount: selectedAccount.id })} />
              </FormControl>
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button onPress={() => onAddFarmHandler()}>Add Farm</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      {/* ========================================================== */}
      {/* Sharing Modal */}

      <Modal isOpen={showSharingAccount} onClose={() => { setShowSharingAccount(false); setSelectedAccount(false); }}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>Share Account</Modal.Header>
          <Modal.Body>

            <Formik
              enableReinitialize
              initialValues={{
                email: '',
                accountLevel: '',
                farmSharing: selectedAccountFarmSharingOptions,
              }}
              onSubmit={(values) => {
                onShareAccountHandler(values);
              }}
            >
              {({
                values, handleChange, handleSubmit,
              }) => (

                <VStack space="3">

                  <Text>Set users to share this account with below.</Text>

                  <FormControl space="1">
                    <FormControl.Label>Email Address</FormControl.Label>
                    <Input autoCapitalize='none' autoCorrect={false} autoCompleteType='email' keyboardType='email-address' value={values.email} placeholder="User email..." onChangeText={handleChange('email')} returnKeyType="done"  />
                  </FormControl>

                  {/* This causes onHoverIn and onHoverOut errors */}
                  <Select selectedValue={values.accountLevel} placeholder="Choose account access level..." onValueChange={handleChange('accountLevel')}>
                    <Select.Item label="Administrator" value="3" />
                    <Select.Item label="Editor" value="2" />
                    <Select.Item label="Viewer" value="1" />
                    <Select.Item label="None" value="0" />
                  </Select>

                  <Divider />

                  <Text>Choose access level for each farm:</Text>

                  <FormControl>

                    <FieldArray
                      name="sharing"
                      render={arrayHelpers => (
                        <VStack space="3">

                          {values.farmSharing.map((name, index) => (
                            <HStack space="3" key={`${index}-${name}`}>

                              <Text w="50%">{values.farmSharing[index].farmName}</Text>

                              <Select minW="50%" selectedValue={values.farmSharing[index].level} placeholder={`Choose "${values.farmSharing[index].farmName}" access level`} onValueChange={handleChange(`farmSharing.${index}.level`)}>
                                <Select.Item label="Administrator" value="3" />
                                <Select.Item label="Editor" value="2" />
                                <Select.Item label="Viewer" value="1" />
                                <Select.Item label="None" value="0" />
                              </Select>

                            </HStack>
                          ))}

                        </VStack>
                      )}
                    />

                  </FormControl>

                  <Button onPress={() => handleSubmit()}>
                    Share
                  </Button>

                  <Divider />

                </VStack>

              )}
            </Formik>

            {!selectedAccount?.sharing ? (
              <Text mt="3">This account isn&apos;t shared with anyone yet.</Text>
            ) : (
              <FlatList
                mt="3"
                data={selectedAccount?.sharing.keySort({ accountLevel: 'desc' })}                
                keyExtractor={item => item.uid}
                renderItem={userShareItem}
              />
            )}

          </Modal.Body>
        </Modal.Content>
      </Modal>

      {/* ========================================================== */}

    </ScreenWrapper>
  );

};

export default FarmListScreen;
