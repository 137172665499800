const NotificationTypes = {
  high: {
    label: 'High',
    description: 'Notifications are triggered if this sensor goes over it\'s limit.'
  },
  low: {
    label: 'Low',
    description: 'Notifications are triggered if this sensor goes under it\'s limit.',
  },
  rate: {
    label: 'Rate of Change',
    description: 'Notifications are triggered if this sensor falls by more than the set percentage between readings.',
    include: ['tank', 'fuel'],
  },
  open: {
    label: 'Open',
    description: 'Notification is triggered when this gate is opened.',
    include: ['gate'],
  },
  close: {
    label: 'Close',
    description: 'Notification is triggered when this gate is closed.',
    include: ['gate'],
  },
};

export default NotificationTypes;