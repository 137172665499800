import React from 'react';

import {
  Center, VStack, Spinner, Heading,
} from 'native-base';

function LoadingIndicator() {
  return (
    <Center flex="1" justifyContent="center">
        <VStack>
            <Spinner accessibilityLabel="Loading..." />
            <Heading color="primary.500" fontSize="md">
                Loading...
            </Heading>
        </VStack>
	</Center>
  );
}

export default LoadingIndicator;
