import { useState } from 'react';
import { useSigninCheck, useAuth } from 'reactfire';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator, DrawerItemList } from '@react-navigation/drawer';

import {
    VStack,
    Button,
    Heading,
} from 'native-base';

import LoadingIndicator from '../components/LoadingIndicator';

// =================================================================================================
// Import Screens

import AuthScreen from '../screens/AuthScreen';
import FarmListScreen from '../screens/FarmListScreen';
import FarmDashboardScreen from '../screens/FarmDashboardScreen';
import SensorHistoryScreen from '../screens/SensorHistoryScreen';
import MyAccountScreen from '../screens/MyAccountScreen';

// =================================================================================================
// Default Options

const defaultNavOptions = {
  headerStyle: { backgroundColor: "grey" },
  headerTitleStyle: {},
  headerBackTitleStyle: {},
  headerTintColor: 'white'
};

// =================================================================================================
// Create Navigators
const LeftMenuDrawer = createDrawerNavigator();
const MainStack = createStackNavigator();
const FarmsStack = createStackNavigator();

// =================================================================================================
// Farms Stack Navigator
const FarmNavigator = () => {
  return (
    <FarmsStack.Navigator screenOptions={defaultNavOptions}>
      <FarmsStack.Screen name="Farm List" component={FarmListScreen} initialParams={{ farmIdToDeleteAccountId: '', farmIdToDelete: '' }} />
      <FarmsStack.Screen name="Farm Dashboard" component={FarmDashboardScreen} />
      <FarmsStack.Screen name="Sensor History" component={SensorHistoryScreen} />
    </FarmsStack.Navigator>
  );
};

// =================================================================================================
// Drawer navigator
const MainNavigator = ({navigation}) => {
  
  // Auth Status
  const auth = useAuth();

  const [loggingOut, setLoggingOut] = useState(false);

  function onLogoutHandler() {
    setLoggingOut(true);
    auth.signOut().then(() => {
      setLoggingOut(false)
    });
  };

  if (loggingOut) {
    return <LoadingIndicator />;
  }

  return (
    <LeftMenuDrawer.Navigator
      drawerPosition="left"
      drawerContent={(props) => {
        return (
          <VStack padding="3" space="3" flex="1" justifyContent="space-between">
              <VStack>
                <Heading size="2xl">FarmIT</Heading>
                <DrawerItemList {...props} />
              </VStack>
              <Button onPress={() => onLogoutHandler()} variant="ghost">Logout</Button>
          </VStack>
        );
      }}
    >
      <LeftMenuDrawer.Screen name="Farms" component={FarmNavigator} options={{ headerShown: false }}/>
      <LeftMenuDrawer.Screen name="My Account" component={MyAccountScreen} />
    </LeftMenuDrawer.Navigator>
  );
};

// =================================================================================================
// App Navigator
const AppNavigator = () => {

  const { status, data: signinCheckResult } = useSigninCheck();

  if (status === 'loading') {
    return <LoadingIndicator />;
  }

  if (signinCheckResult.signedIn === true) {
    return (
      <NavigationContainer>
        <MainStack.Navigator screenOptions={defaultNavOptions}>
          <MainStack.Screen name="MainNavigator" component={MainNavigator} options={{ headerShown: false }} />
        </MainStack.Navigator>
      </NavigationContainer>
    );
  } else {
    return (
      <NavigationContainer>
        <MainStack.Navigator screenOptions={defaultNavOptions}>
          <MainStack.Screen name="AuthScreen" component={AuthScreen} options={{ headerShown: false }} />
        </MainStack.Navigator>
      </NavigationContainer>
    );
  }
};

export default AppNavigator;
