/* eslint-disable react-native/no-raw-text */
import React, { useState, useEffect, useRef } from 'react';
// import { StackActions } from '@react-navigation/native';
import { useAuth, useSigninCheck } from 'reactfire'; // , useFirestore
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail } from "firebase/auth";

import { getFirestore, setDoc, doc } from 'firebase/firestore';

import {
  Center, Box, VStack, HStack, FormControl, Heading, Text, Input, Button, Divider, Stack, Icon, Alert, IconButton, CloseIcon,
} from 'native-base'; // , Checkbox , WarningOutlineIcon , useToast
import { MaterialCommunityIcons } from '@expo/vector-icons'; // MaterialIcons,

import LoadingIndicator from '../components/LoadingIndicator';

const AuthScreen = () => {

  const { status, data: signinResult } = useSigninCheck();

  const firestore = getFirestore();

  const signedIn = signinResult?.signedIn || null;
  const user = signinResult?.user || null;

  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [authCase, setAuthCase] = useState('login');
  const [authAlert, setAuthAlert] = useState(false);

  // =========================================================================
  // Form Fields

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // =========================================================================
  // Refs
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const emailInput = useRef(null);
  const passwordInput = useRef(null);

  // =========================================================================
  // Action Handlers

  async function handleLoginWithEmail() {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setIsLoading(false);
      setAuthAlert({type: 'error', title: 'Sorry, there\'s a problem.', message: err.message });
      console.error(err);
    }
  }

  async function handleLoginWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      setIsLoading(true);
      await signInWithPopup(auth, provider)
        .then((user) => {
          // TODO: resolve this. it overwrites my isSuperAdmin boolean
          // const { uid, email } = user.user;
          // setDoc(doc(firestore, "users", uid), {
          //   uid,
          //   email,
          // });
        });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setAuthAlert({type: 'error', title: 'Sorry, there\'s a problem.', message: err.message });
      console.error(err);
    }
  }

  async function handleRegister() {
    try {
      setIsLoading(true);
      await createUserWithEmailAndPassword(auth, email, password)
        .then((user) => {
          const { uid } = user.user;
          setDoc(doc(firestore, "users", uid), {
            uid,
            email,
          });
        });
        setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setAuthAlert({type: 'error', title: 'Sorry, there\'s a problem.', message: err.message });
      console.error(err);
    }
  }

  async function handleForgot() {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        setAuthAlert({type: 'success', title: 'Thanks.', message: 'We\'ve reset your password. Please check your inbox.' });
      });
    } catch (err) {
      setIsLoading(false);
      setAuthAlert({type: 'error', title: 'Sorry, there\'s a problem.', message: err.message });
      console.error(err);
    }
  }

  // Reset alert on any input
  useEffect(() => {
    if (authAlert !== false) {
      setAuthAlert(false);
    }
  }, [email, password])

  // =========================================================================
  // Render loading spinner

  if (isLoading || status === 'loading') {
    return (
      <LoadingIndicator />
    );
  }

  // =========================================================================
  // Main Render
  
  return (

    <Center flex="1" justifyContent="center">

      <Heading size="2xl" pb="2">FarmIT</Heading>

      <Box w="88%" maxW="300" p="3" backgroundColor="white" borderRadius="6">

        <VStack space="3">

          {authCase === 'login' ? <Heading size="md">Login</Heading> : null}
          {authCase === 'register' ? <Heading size="md">Register</Heading> : null}
          {authCase === 'forgotPassword' ? <Heading size="md">Forgot Password</Heading> : null}

          {authAlert ? (
            <Alert w="100%" maxW="400" status={authAlert.type} colorScheme={authAlert.type}>
              <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                  <HStack flexShrink={1} space={2} alignItems="center">
                    <Alert.Icon />
                    <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                      {authAlert.title}
                    </Text>
                  </HStack>
                  <IconButton variant="unstyled" icon={<CloseIcon size="3" color="coolGray.600" />} onPress={() => {setAuthAlert(false)}} />
                </HStack>
                <Box pl="6" _text={{
                  color: "coolGray.600"
                }}>
                  {authAlert.message}
                </Box>
              </VStack>
            </Alert>
          ) : null }

          {authCase === 'register' ? (
            <FormControl isRequired>
              <Stack>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChangeText={(val) => setFirstName(val)}
                  autoCapitalize="none"
                  autoCorrect={false}
                  returnKeyLabel="Next"
                  returnKeyType="next"
                  ref={firstNameInput}
                  onSubmitEditing={() => {
                    lastNameInput.current.focus();
                  }}
                />
              </Stack>
            </FormControl>
          ) : null}

          {authCase === 'register' ? (
            <FormControl isRequired>
              <Stack>
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChangeText={(val) => setLastName(val)}
                  autoCapitalize="none"
                  autoCorrect={false}
                  returnKeyLabel="Next"
                  returnKeyType="next"
                  ref={lastNameInput}
                  onSubmitEditing={() => {
                    emailInput.current.focus();
                  }}
                />
              </Stack>
            </FormControl>
          ) : null}

          {/* TODO: force email to be lowercase */}

          <FormControl isRequired>
            <Stack>
              <Input
                placeholder="Email Address"
                autoCapitalize="none"
                autoCompleteType="email"
                autoCorrect={false}
                keyboardType="email-address"
                value={email}
                onChangeText={(value) => {
                  setEmail(value);
                }}
                returnKeyLabel="Next"
                returnKeyType="next"
                ref={emailInput}
                onSubmitEditing={() => {
                  authCase === 'forgotPassword' ? onForgotPasswordPressHandler() : passwordInput.current.focus();
                }}
              />
            </Stack>
          </FormControl>

          {authCase !== 'forgotPassword' ? (
            <FormControl isRequired>
              <Stack>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  autoCompleteType="password"
                  autoCorrect={false}
                  value={password}
                  InputRightElement={<Icon as={<MaterialCommunityIcons name={showPassword ? 'eye' : 'eye-off'} />} size={5} mr="2" color="muted.400" onPress={() => setShowPassword(!showPassword)} />}
                  onChangeText={(value) => {
                    setPassword(value);
                  }}
                  onSubmitEditing={() => {
                    authCase === 'login' ? handleLoginWithEmail() : handleRegister();
                  }}
                  returnKeyLabel="Go"
                  returnKeyType="go"
                  ref={passwordInput}
                />
              </Stack>
            </FormControl>
          ) : null}

          {authCase === 'login' ? <Button disabled={!!(email === '' || password === '')} opacity={email === '' || password === '' ? '0.25' : '1'} onPress={() => handleLoginWithEmail()}>Login</Button> : null}

          {authCase === 'login' ? <Center><Text fontSize="xs">Or</Text></Center> : null}

          {authCase === 'register' ? <Button disabled={!!(firstName === '' || lastName === '' || email === '' || password === '')} opacity={firstName === '' || lastName === '' || email === '' || password === '' ? '0.25' : '1'} onPress={() => handleRegister()}>Register</Button> : null}

          {authCase === 'register' ? <Center><Text fontSize="xs">Or</Text></Center> : null}

          {authCase === 'forgotPassword' ? <Button disabled={!!(email === '')} opacity={email === '' ? '0.25' : '1'} onPress={() => handleForgot()}>Reset Password</Button> : null}

          {authCase === 'forgotPassword' ? <Center><Text fontSize="xs">Or</Text></Center> : null}

          {/* To swap between auth actions */}

          {authCase === 'register' || authCase === 'forgotPassword' ? <Button variant="outline" onPress={() => setAuthCase('login')}>Login</Button> : null}

          {authCase === 'login' ? <Button variant="outline" onPress={() => setAuthCase('register')}>Register</Button> : null}

          {authCase === 'login' || authCase === 'register' ? <Divider /> : null}

          {authCase === 'login' || authCase === 'register' ? <Button leftIcon={<Icon as={MaterialCommunityIcons} name="google" size="sm" />} onPress={() => handleLoginWithGoogle()}>Sign in with Google</Button> : null}

          {authCase === 'login' ? <Button variant="link" onPress={() => setAuthCase('forgotPassword')}>Forgot Password?</Button> : null}

        </VStack>

      </Box>

    </Center>

  );
};

export const screenOptions = () => {
  return {
    headerShown: false,
  };
};

export default AuthScreen;
