/* eslint-disable react-native/no-raw-text */
/* eslint-disable use-isnan */
/* eslint-disable no-empty */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react'; // , useRef
import { Dimensions } from 'react-native'; // , View
import { useFirestore } from 'reactfire'; // , useUser, useFirestoreCollectionData, useFirestoreDocData
import { setDoc, collection, doc, query, where, orderBy, onSnapshot } from 'firebase/firestore'; // addDoc,  getDocs, collectionGroup,

// import { NavigationActions, StackActions, CommonActions } from '@react-navigation/native';

import {
  Input, Button, Icon, Text, HStack, VStack, Divider, useToast, IconButton, FlatList, FormControl, Heading, Checkbox, Modal, Select,
} from 'native-base'; // Center, Box,

import { MaterialCommunityIcons } from '@expo/vector-icons';

import { LineChart } from 'react-native-chart-kit';
// import { Rect, Text as TextSVG, Svg } from 'react-native-svg';
import { format, subDays, subMonths, isWithinInterval } from 'date-fns';

import { Formik } from 'formik';

import SensorTypes from '../settings/SensorTypes';
import NotificationTypes from '../settings/NotificationTypes';

import LoadingIndicator from '../components/LoadingIndicator';
import ScreenWrapper from '../components/ScreenWrapper';

// Main entry function
const SensorHistoryScreen = ({ props, route, navigation }) => {

  const { accountId, farmId, sensorId, sensorName, userFarmAccessLevel } = route.params;

  // Toast
  const toast = useToast();

  // Load Firestore
  const firestore = useFirestore();

  // Set initial state
  const [loading, setLoading] = useState(true);
  const [sensorData, setSensorData] = useState({
    sensorName: '',
    sensorType: 'unset',
  });

  const [rawReadingsData, setRawReadingsData] = useState();
  const [filteredReadingsData, setFilteredReadingsData] = useState();
  // const [filteredGraphData, setFilteredGraphData] = useState([[], [], [], [], [], []]);

  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDataset, setGraphDataset] = useState(false);

  const [yAxisSuffix, setYAxisSuffix] = useState();

  const [editingSensor, setEditingSensor] = useState(false);
  const [viewingRawData, setViewingRawData] = useState(false);

  const [sensorTypeOptions, setSensorTypeOptions] = useState([]);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [notificationTypeOptions, setNotificationTypeOptions] = useState([]);
  const [notificationMethodOptions, setNotificationMethodOptions] = useState([]);

  const [sensorHasDisplayOptions, setSensorHasDisplayOptions] = useState(false);
  const [sensorDisplaySelectOptions, setSensorDisplaySelectOptions] = useState([
    { label: 'No Options', value: null }
  ]);
  const [sensorDisplayRange, setSensorDisplayRange] = useState(7);
  const [sensorDisplayOption, setSensorDisplayOption] = useState();

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  // This needs a better name
  const intervalSelectOptions = [
    // { label: 'All', value: 10000000 }, // Million provides all data...
    { label: 'Last Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '30 Days', value: 30 },
    { label: '60 Days', value: 60 },
    { label: '90 Days', value: 90 },
    { label: '180 Days', value: 180 },
    // { label: 'Last Year', value: 365 }
  ];

  const [confirmDelete, setConfirmDelete] = useState(false);

  // const [tooltipPos, setTooltipPos] = useState({
  //   x: 0, y: 0, visible: false, value: 0
  // });

  // =============================================================================
  // Load sensor config, then load readings for sensor.
  useEffect(() => {

    // Load Sensor Type Options
    const sensorTypes = [];
    Object.keys(SensorTypes).forEach((type) => {
      sensorTypes.push({ value: type, label: SensorTypes[type].label });
    });
    setSensorTypeOptions(sensorTypes);

    // Load Fuel Type Options
    const loadedFuelTypes = SensorTypes.fuel.fuelTypes;
    const fuelTypes = [];
    Object.keys(loadedFuelTypes).forEach((type) => {
      fuelTypes.push({ value: type, label: loadedFuelTypes[type].label });
    });
    setFuelTypeOptions(fuelTypes);

    return onSnapshot(doc(firestore, "/accounts/" + accountId + '/farms/' + farmId + '/sensors/', sensorId), (documentSnapshot) => {

      // Save sensor metadata
      let data = documentSnapshot.data();
      if(typeof data === 'undefined') {
        return;
      }
      
      // Override old string storage of notification type
      let { sensorNotifyType=[] } = data;
      if (typeof sensorNotifyType === 'string') {
        if (sensorNotifyType === 'highlow') {
          sensorNotifyType = ['high', 'low'];
        } else {
          sensorNotifyType = [sensorNotifyType];
        }
      }
      data = { ...data, sensorNotifyType };
      setSensorData(data);

      const { sensorType } = data;
      const customUnit = data.sensorDisplayOption;

      if (sensorType !== undefined) {
        if (customUnit) {
          setYAxisSuffix(customUnit);
        } else {
          setYAxisSuffix(SensorTypes[data.sensorType].readingValue1Unit);
        }
      }      
      console.log('sensor config loaded');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, farmId, sensorId]);

  // Sensor Data updated - load Notification Options & display options
  // and load readings data from firebase
  useEffect(() => {

    const notificationTypes = [];
    Object.keys(NotificationTypes).forEach((type) => {
      if (type === 'rate') {
        if (sensorData.sensorType === 'tank' || sensorData.sensorType === 'fuel') {
          notificationTypes.push({ value: type, label: NotificationTypes[type].label });
        }
      } else if (type === 'open' || type === 'close') {
        if (sensorData.sensorType === 'gate') {
          notificationTypes.push({ value: type, label: NotificationTypes[type].label });
        }
      } else if (type === 'high' || type === 'low') {
        const excludedTypes = ['dam', 'rain', 'gate', 'relay'];
        if (!excludedTypes.includes(sensorData.sensorType)) {
          notificationTypes.push({ value: type, label: NotificationTypes[type].label });
        }
      }
    });
    setNotificationTypeOptions(notificationTypes);

    const notificationMethods = [];
    notificationMethods.push({ value: "sms", label: "SMS" });
    notificationMethods.push({ value: "email", label: "Email" });
    setNotificationMethodOptions(notificationMethods);

    // Set data display options from sensor type
    const type = sensorData?.sensorType;
    const options = SensorTypes[type]?.displayOptions;

    // Check if they've loaded.
    if (options && type !== undefined) {

      setSensorHasDisplayOptions(true);

      const displayOptions = [];
      Object.keys(options).forEach((option) => {
        displayOptions.push({ value: options[option].value, label: options[option].label });
      });

      setSensorDisplaySelectOptions(displayOptions);

    }

    // const loadedDisplayOptions = SensorTypes.fuel.fuelTypes;

    // Set display option
    if (sensorData?.sensorDisplayOption) {
      setSensorDisplayOption(sensorData?.sensorDisplayOption);
    }

    if (sensorData?.sensorDisplayRange) {
      setSensorDisplayRange(sensorData?.sensorDisplayRange);
    }

    if (!sensorData?.sensorReadingsId || !sensorData?.sensorDisplayRange) {
      return;
    }
    
    const displayRange = sensorData?.sensorDisplayRange?? 1;    


    // Load readings data
    const sensorReadingsId = sensorData?.sensorReadingsId.toString();
    if (sensorReadingsId) {
      
      // Show only 6 months to temporarily patch performance issues
      const today = new Date();
      const fromDate = subDays(today, displayRange);
            
      const q = query(collection(firestore, '/sensors/' + sensorReadingsId + '/readings/'), where("readingTime", ">", fromDate), orderBy('readingTime', 'asc'));
      return onSnapshot(q, (querySnapshot) => {

        const list = [];

        querySnapshot.forEach((reading) => {

          const {
            addedTime, readingTime, value1, value2, value3, voltage, currentTankVolume, currentTankVolumePercentage, statusRadioCode
          } = reading.data();

          // Only add if statusCode is '52' or '54'
          if (statusRadioCode && ( statusRadioCode === '52' || statusRadioCode === '54' )) {
            // Push data to list
            list.push({
              id: reading.id,
              addedTime,
              readingTime,
              value1,
              value2,
              value3,
              voltage,
              currentTankVolume,
              currentTankVolumePercentage
            });  
          }            

        });

        // Store all data
        setFilteredReadingsData(list);
        setRawReadingsData(list.slice().reverse());
        console.log('readings data loaded', sensorData);
      });
    }

  }, [sensorData]);


  // =============================================================================
  // Update graphed data when it's changed.
  useEffect(() => {

    // Get around first load issue.
    if (!filteredReadingsData) {
      setLoading(false);
      return;
    }

    const type = sensorData?.sensorType;

    if (type === undefined) {
      return;
    }

    // x axis labels
    const labels = [];
    // main data
    const dataSeries1 = [];
    // high alert
    const dataSeries2 = [];
    // low alert
    const dataSeries3 = [];

    // Convert to array for forEach loop
    // const readings = Object.values(filteredReadingsData);

    // const endDate = new Date();
    // let startDate;

    // if (sensorDisplayRange === 0) {
    //   startDate = subDays(new Date(), 30);  // sdp was 10000
    // } else {
    //   startDate = subDays(new Date(), sensorDisplayRange);
    // }

    const {
      sensorType,
      sensorNotifyType,
      sensorNotifyMethod,
      sensorLowerLimit,
      sensorUpperLimit,
      sensorTankDiameter,
      sensorTankLength,
      sensorTankOrientation,
      sensorFuelType,
      sensorFuelDensity,
    } = sensorData;

    const defaultUnit = SensorTypes[sensorType].readingValue1Unit;

    let scalingValue;
    if (sensorType === 'fuel') {
      switch (sensorFuelType) {
        case 'petrol':
          scalingValue = 1000 / 800; // 1.25
          break;
        case 'diesel':
          scalingValue = 1000 / 850; // 1.1764
          break;
        case 'other':
          scalingValue = 1000 / sensorFuelDensity; //
          break;
        default:
          break;
      }
    } else {
      scalingValue = 1;
    }

    // let sensorTankDiameter;
    // let sensorTankLength;
    // let totalTankVolume;

    // if (sensorType === 'tank' || sensorType === 'fuel') {
    //   sensorTankDiameter = parseFloat(sensorData.sensorTankDiameter);
    //   sensorTankLength = parseFloat(sensorData.sensorTankLength);
    //   totalTankVolume = (Math.PI * ((sensorTankDiameter / 2) ** 2)) * sensorTankLength;
    // }

    // Process readings into graph data
    filteredReadingsData.forEach((reading) => {

      const {
        readingTime,
        value1,
        value2,
        value3,
        // voltage,
        currentTankVolume,
        currentTankVolumePercentage
      } = reading;

      // Format & Push Labels
      if (sensorDisplayRange === 1) {
        const readingTimeNice = format(new Date(readingTime.toDate()), 'EEE d/M h:mm a');
        labels.push(readingTimeNice);
      } else {
        const readingTimeNice = format(new Date(readingTime.toDate()), 'EEE d/M');
        labels.push(readingTimeNice);                  
      }

      // Moved to admin
      let updatedValue1;

      updatedValue1 = value1;

      if (sensorDisplayOption === defaultUnit) {
        updatedValue1 = value1;
      } else if (sensorDisplayOption === 'L') {
        updatedValue1 = currentTankVolume;
      } else if (sensorDisplayOption === '%') {
        updatedValue1 = currentTankVolumePercentage;
      }

      // Push data for graphing
      dataSeries1.push(updatedValue1);
      dataSeries2.push(parseFloat(value2));
      dataSeries3.push(parseFloat(value3));

    });

    setGraphLabels(labels); // used for graph title from and to date

    const sensorUpperVal = sensorData.sensorUpperLimit ? sensorData.sensorUpperLimit : 0;
    const sensorLowerVal = sensorData.sensorLowerLimit ? sensorData.sensorLowerLimit : 0;

    const defaultUpperVal = parseFloat(sensorUpperVal);
    const defaultLowerVal = parseFloat(sensorLowerVal);
    let upperValLitres;
    let upperVal;
    let lowerValLitres;
    let lowerVal;

    let totalTankVolume;

    if (sensorType === 'fuel' || sensorType === 'tank') {
      totalTankVolume = ((Math.PI * ((sensorTankDiameter / 2) ** 2)) * (sensorTankLength * scalingValue)) / 1000000;
      if (sensorTankOrientation === 'v') {
        upperValLitres = ((Math.PI * ((sensorTankDiameter / 2) ** 2)) * (sensorUpperLimit * scalingValue)) / 1000000;
        lowerValLitres = ((Math.PI * ((sensorTankDiameter / 2) ** 2)) * (sensorLowerLimit * scalingValue)) / 1000000;
      } else if (sensorTankOrientation === 'h') {
        upperValLitres = (volume(sensorTankDiameter, (sensorUpperLimit * scalingValue), sensorTankLength)) / 1000000;
        lowerValLitres = (volume(sensorTankDiameter, (sensorLowerLimit * scalingValue), sensorTankLength)) / 1000000;
      }
    }

    if (sensorDisplayOption === 'L') {
      upperVal = upperValLitres;
      lowerVal = lowerValLitres;
    } else if (sensorDisplayOption === '%') {
      upperVal = Math.round((upperValLitres / totalTankVolume) * 100);
      lowerVal = Math.round((lowerValLitres / totalTankVolume) * 100);
    } else {
      upperVal = defaultUpperVal;
      lowerVal = defaultLowerVal;
    }

    const upperSeries = [];
    const lowerSeries = [];

    // for (let i = 0; i < filteredReadingsData.length; i++) {
    //   upperSeries.push(upperVal);
    //   lowerSeries.push(lowerVal);
    // }
    for (let i = 0; i < dataSeries1.length; i++) {
      upperSeries.push(upperVal);
      lowerSeries.push(lowerVal);
    }

    // Merge data series for graph
    let datasets = [];
    datasets = [
      {
        data: dataSeries1,
        color: (opacity = 1) => 'rgb(0, 0, 0)',
      },
      // {
      //   data: dataSeries2.reverse(),
      //   color: (opacity = 1) => 'rgb(255, 0, 0)', // optional
      //   strokeWidth: 1 // optional
      // },
      // {
      //   data: dataSeries3.reverse(),
      //   color: (opacity = 1) => 'rgb(255, 0, 0)', // optional
      //   strokeWidth: 1 // optional
      // },
      // upperSeries,
      // lowerSeries,
    ];

    const notifyColor = 'rgb(255, 0, 0)';
    const ignoreColor = 'rgb(208, 208, 208)';

    // High Warning
    if (typeof sensorUpperLimit !== 'undefined') {
      let upperLimitColor;
      if (sensorNotifyType.includes('high')) {
        upperLimitColor = notifyColor;
      } else {
        upperLimitColor = ignoreColor;
      }
      datasets.push({
        data: upperSeries,
        color: (opacity = 1) => upperLimitColor, // optional
        strokeWidth: 1 // optional
      });
    }

    // Low Warning
    if (typeof sensorLowerLimit !== 'undefined') {
      let lowerLimitColor;
      if (sensorNotifyType.includes('low')) {
        lowerLimitColor = notifyColor;
      } else {
        lowerLimitColor = ignoreColor;
      }
      datasets.push({
        data: lowerSeries,
        color: (opacity = 1) => lowerLimitColor, // optional
        strokeWidth: 1 // optional
      });
    }

    // Reduce number of labels to suit graph width
    // below 450px width show none, above about one label for every 190 pixels
    const maxToShow = dimensions.width > 450 ? Math.floor( dimensions.width/190) : 0;
    
    // divide the total number of labels into maxToShowGroups
    const labelRadix = Math.max(Math.floor(labels.length / maxToShow), 1);

    const showLabels = labels.map((label, idx) => idx % labelRadix === 0 ? label : '');

    //clear the first label
    showLabels[0] = '';

    const dataset = { labels: showLabels, datasets };

    setGraphDataset(dataset);
    setLoading(false);
    console.log('graph data loaded');

  }, [filteredReadingsData, dimensions]);


  // ========================================================================
  // Configure Header

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HStack space="3">
          {userFarmAccessLevel > 1 ? (
            <IconButton icon={<Icon as={MaterialCommunityIcons} name="file-edit" color="white" />} onPress={() => setEditingSensor(true)} />
          ) : null}          
        </HStack>
      ),
      headerTitle: sensorData?.sensorName || sensorId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, sensorData]);

  
  // =============================================================================
  // cause redraw on window size change with debounce  https://www.pluralsight.com/guides/re-render-react-component-on-window-resize

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 200)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    
    }
  })

  
  // used with window size change refresh
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }



  // Volume calculation function
  function volume(diameter, depth, length) {
    let value;
    const R = diameter / 2;
    if (Math.acos((R - depth) / R) !== NaN) {
      const a = Math.pow(R, 2) * Math.acos((R - depth) / R) - (R - depth) * (Math.pow((2 * R * depth - Math.pow(depth, 2)), 0.5));
      value = a * length;
    } else {
      value = 0;
      console.warn("Cylinder radius can't be less than depth");
    }
    return value;
  }

  // =============================================================================
  // Chart Stuff
  // https://github.com/indiespirit/react-native-chart-kit

  // Maybe look at https://github.com/N1ghtly/react-native-responsive-linechart

  const width = Dimensions.get('window').width - 80;
  const height = 250;

  const getModalSize = () => {
    return Dimensions.get('window').width < 420 ? "full": "lg";
  }

  // Chart Styles
  const chartConfig = {
    backgroundColor: '#ffffff',
    backgroundGradientFrom: '#ffffff',
    backgroundGradientFromOpacity: 1,
    backgroundGradientTo: '#ffffff',
    backgroundGradientToOpacity: 1,
    fillShadowGradientOpacity: 1,
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    decimalPlaces: 1,
  };

  // Change shown data
  function onChangeDataRangeShown(value) {
    setLoading(true);
    setSensorDisplayRange(value);
    updateSensor({ sensorDisplayRange: parseInt(value, 10) });
    setLoading(false);
  }

  function onChangeDisplayOption(value) {
    setLoading(true);
    setSensorDisplayOption(value);
    setYAxisSuffix(value);
    updateSensor({ sensorDisplayOption: value });
    setLoading(false);
  }

  // ===================================================================================
  // SAVE
  // ===================================================================================

  // Remove empty fields
  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] == null && obj[key]);
    return obj;
  };

  // Handle update data
  function onUpdateSensorHandler(values) {
    setEditingSensor(false);
    setLoading(true);
    updateSensor(removeEmpty(values));
    setLoading(false);
  }

  async function updateSensor(values) {
    await setDoc(doc(firestore, "/accounts/" + accountId + '/farms/' + farmId + '/sensors/', sensorId), values, { merge: true })
    .then(() => {
      toast.show({
        title: `Changes saved`,
        status: 'success',
        placement: 'bottom'
      });
    })
    .catch((error) => { toast.show({ title: `Error: ${error}`, status: 'error', placement: 'bottom' }); console.error(error); });
  }

  // ==============================
  // Delete Sensor

  async function onDeleteSensorHandler() {
    // navigation.navigate('Farm Dashboard', {accountId, farmId, userFarmAccessLevel, sensorToDelete: sensorId, sensorReadingsId: sensorData.sensorReadingsId });
    setLoading(true);
    navigation.navigate('Farm Dashboard', {
      accountId,
      farmId,
      userFarmAccessLevel,
      sensorToDelete: sensorId,
      sensorReadingsId: sensorData.sensorReadingsId
    },
    null,
    Math.random().toString()
    );

  }

  // ======================================
  // Render Items

  // raw data Header
  const rawDataHeader = () => {
    return (
      <VStack>
        <Divider />
        <HStack space="1" flex="1">
          <Text w="40%" p="1">Read Time/Date</Text>
          <Text w="12%" p="1">R 1</Text>
          <Text w="12%" p="1">R 2</Text>
          <Text w="12%" p="1">R 3</Text>
          <Text w="12%" p="1">Bat</Text>
          <Text w="12%" p="1">Volts</Text>
        </HStack>
      </VStack>
    );
  };

  // Readings Render Item
  const renderReadingItem = (itemData) => {
    return (
      <VStack>
        <Divider />
        <HStack space="1" flex="1">
          <Text w="40%" p="1">{format(new Date(itemData.item.readingTime.toDate()), 'hh:mm a, d-M-yy ')}</Text>
          <Text w="12%" p="1">{Math.round(itemData.item.value1)}</Text>
          <Text w="12%" p="1">{itemData.item.value2}</Text>          
          <Text w="12%" p="1">{itemData.item.value3}</Text>          
          <Text w="12%" p="1">{itemData.item.voltage > 2.7 ? 'OK' : 'Low'}</Text>
          <Text w="12%" p="1">{itemData.item.voltage}</Text>
        </HStack>
      </VStack>
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  // ========================================================================
  // Render
  return (
    <ScreenWrapper>

      {typeof sensorData?.sensorType !== 'undefined' && sensorData?.sensorType !== 'relay' && sensorData?.sensorType !== 'gate' ? (
        <VStack space="3">
          {typeof sensorData?.sensorDisplayRange !== 'undefined' && graphLabels?.length > 0 ? (
            <Text>
              {sensorData?.sensorDisplayRange === 0 ? 'Showing all data.' : `Showing data from ${graphLabels[0]} to ${graphLabels[graphLabels.length - 1]}.`}
            </Text>
          ) : 
            <Text>
              No Data Available.
            </Text>
          }

          {graphDataset ? (
            <LineChart
              data={graphDataset}
              width={width}
              height={height}
              chartConfig={chartConfig}
              bezier
              yAxisSuffix={yAxisSuffix}
              withDots={false}
              withShadow={false}
              fromZero
              withVerticalLines={false}
            />
          ) : null}

          <Text bold>Chart Options</Text>

          <VStack space="1">

            <VStack space="1">
              <Text>Data Range</Text>
              <Select selectedValue={sensorDisplayRange} placeholder="Choose data range" onValueChange={(itemValue) => onChangeDataRangeShown(itemValue)}>
                {intervalSelectOptions.map((option) => (
                  <Select.Item label={option.label} value={option.value} key={option.label}/>
                ))}
              </Select>
            </VStack>

            {sensorHasDisplayOptions ? (
              <VStack space="1">
                <Text>Display Option</Text>
                <Select selectedValue={sensorDisplayOption} placeholder="Choose display option..." onValueChange={(itemValue) => onChangeDisplayOption(itemValue)}>
                  {sensorDisplaySelectOptions.map((option) => (
                    <Select.Item label={option.label} value={option.value} key={option.label}/>
                  ))}
                </Select>
              </VStack>
            ) : null}

          </VStack>

        </VStack>
      ) : (
        null
      )}

      {sensorData?.sensorType === 'gate' && filteredReadingsData && filteredReadingsData[0]?.value1 === 0 ? (
        <Text>
          Gate is open.
        </Text>
      ) : null}

      {sensorData?.sensorType === 'gate' && filteredReadingsData && filteredReadingsData[0]?.value1 === 1 ? (
        <Text>
          Gate is closed.
        </Text>
      ) : null}

      <Divider />

      {sensorData?.sensorType === 'fuel' && !!sensorData?.sensorFuelType ? (
        <Text>{`Fuel Type: ${sensorData.sensorFuelType}`}</Text>
      ) : null}

      {sensorData?.sensorType === 'fuel' && sensorData?.sensorFuelType === 'other' && sensorData?.sensorFuelDensity !== '' ? (
        <Text>{`Density: ${sensorData.sensorFuelDensity}`}</Text>
      ) : null}

      {filteredReadingsData && filteredReadingsData.length === 0 ? (
        <Text>This sensor doesn&apos;t have any readings.</Text>
      ) : (
        <HStack>
          <Button variant="outline" onPress={() => setViewingRawData(true)}>Raw Data</Button>
        </HStack>
      )}

      {/* Raw Data Modal */}
      <Modal isOpen={viewingRawData} onClose={() => setViewingRawData(false)} size={getModalSize()}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>Viewing Raw Data</Modal.Header>
          <Modal.Body>
            <VStack space="2">
              <Text>Showing data from {graphLabels[graphLabels.length - 1]} to {graphLabels[0]}</Text>
              <FlatList
                ListHeaderComponent={rawDataHeader}
                data={rawReadingsData}
                keyExtractor={(item, index) => `mtttckey${index}`}
                renderItem={renderReadingItem}
              />
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>

      {/* Edit Sensor Modal */}
      <Modal isOpen={editingSensor} onClose={() => setEditingSensor(false)} size={getModalSize()}>
        <Modal.Content maxWidth="600px">
          <Modal.CloseButton />
          <Modal.Header>Edit sensor</Modal.Header>
          <Formik
            enableReinitialize
            initialValues={{
              sensorName: sensorData?.sensorName || "",
              sensorType: sensorData?.sensorType ? sensorData.sensorType : null,
              sensorDisplayOption: sensorData?.sensorDisplayOption || "",
              sensorDisplayRange: sensorData?.sensorDisplayRange || '7',
              sensorLatitude: sensorData?.sensorLatitude || "",
              sensorLongitude: sensorData?.sensorLongitude || "",
              sensorUpdateInterval: sensorData?.sensorUpdateInterval || "",
              sensorReadingMultiplier: sensorData?.sensorReadingMultiplier || "",
              sensorReadingMultiplierUnit: sensorData?.sensorReadingMultiplierUnit || "",
              sensorLowerLimit: sensorData?.sensorLowerLimit || "",
              sensorUpperLimit: sensorData?.sensorUpperLimit || "",
              sensorTankWarnOnPercentageDrop: sensorData?.sensorTankWarnOnPercentageDrop || "",
              sensorCalibrationValue: sensorData?.sensorCalibrationValue || "",
              sensorTankOrientation: sensorData?.sensorTankOrientation || 'v',
              sensorTankLength: sensorData?.sensorTankLength || "",
              sensorTankDiameter: sensorData?.sensorTankDiameter || "",
              sensorFuelType: sensorData?.sensorFuelType || "",
              sensorFuelDensity: sensorData?.sensorFuelDensity || "",
              sensorNotifyType: sensorData?.sensorNotifyType || [],
              sensorNotifyMethod: sensorData?.sensorNotifyMethod || [],
              sensorIgnoring: sensorData?.sensorIgnoring || false,
            }}

            onSubmit={(values) => {
              onUpdateSensorHandler(values);
            }}
          >
            {({
              handleChange, handleBlur, handleSubmit, values, setFieldValue
            }) => (
              <>
                <Modal.Body>

                  <VStack space="3">

                    {/* {!values.sensorType ? (
                    ) : (
                      null
                    )} */}

                    <FormControl space="1">
                      <FormControl.Label>Sensor Type</FormControl.Label>
                      <Select selectedValue={values.sensorType} placeholder="Choose display option..." onValueChange={(itemValue) => setFieldValue('sensorType', itemValue, false)}>
                        {sensorTypeOptions.map((option) => (
                          <Select.Item label={option.label} value={option.value} key={option.label} />
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl space="1">
                      <FormControl.Label>Sensor Name</FormControl.Label>
                      <Input
                        onChangeText={handleChange('sensorName')}
                        onBlur={handleBlur('sensorName')}
                        value={values.sensorName}
                        returnKeyType="next" />
                    </FormControl>

                    {/* Water/Fuel Tank Sensors */}
                    {values.sensorType === 'tank' || values.sensorType === 'fuel' ? (
                      <VStack space="3">

                        <Divider />

                        <Heading size="sm">Tank Specifications</Heading>

                        <HStack alignItems="center" space={3}>
                          <Text>Orientation</Text>
                          <Select selectedValue={values.sensorTankOrientation} placeholder="Choose orientation" onValueChange={(itemValue) => setFieldValue('sensorTankOrientation', itemValue, false)}>
                            <Select.Item label="Vertical" value="v" key="orient-v" />
                            <Select.Item label="Horizontal" value="h" key="orient-h" />
                          </Select>
                        </HStack>

                        {values.sensorTankOrientation ? (
                          <>

                            <HStack alignItems="center" space={3}>
                              <Text>{values.sensorTankOrientation === 'v' ? 'Height' : 'Length'}</Text>
                              <Input
                                onChangeText={handleChange('sensorTankLength')}
                                onBlur={handleBlur('sensorTankLength')}
                                value={values.sensorTankLength}
                              />
                              <Text>mm</Text>
                            </HStack>

                            <HStack alignItems="center" space={3}>
                              <Text>Diameter</Text>
                              <Input
                                onChangeText={handleChange('sensorTankDiameter')}
                                onBlur={handleBlur('sensorTankDiameter')}
                                value={values.sensorTankDiameter}
                              />
                              <Text>mm</Text>
                            </HStack>

                          </>
                        ) : null}

                      </VStack>
                    ) : null}
                    
                    {/* Fuel Tank Sensors */}
                    {values.sensorType === 'fuel' ? (
                      <FormControl space="1">
                        <FormControl.Label>Fuel Type</FormControl.Label>
                        <Select selectedValue={values.sensorFuelType} placeholder="Choose display option..." onValueChange={(itemValue) => setFieldValue('sensorFuelType', itemValue, false)}>
                          {fuelTypeOptions.map((option) => (
                            <Select.Item label={option.label} value={option.value} key={option.label} />
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}

                    {values.sensorType === 'fuel' && values.sensorFuelType === 'other' ? (
                      <FormControl space="1">
                        <FormControl.Label>Fuel Density</FormControl.Label>
                        <Input
                          onChangeText={handleChange('sensorFuelDensity')}
                          onBlur={handleBlur('sensorFuelDensity')}
                          value={values.sensorFuelDensity}
                          returnKeyType="next" />
                        <Text>Enter fuel density in kg/m3"</Text>
                      </FormControl>
                    ) : null}

                    {/* Remove options for sensors that don't have any */}
                    {values.sensorType === 'relay' || values.sensorType === 'gate' ? (
                      null
                    ) : (
                      <>

                        {/* Remove Update Interval for Fridges */}
                        {values.sensorType === 'fridge' ? (
                          null
                        ) : (
                          <>

                            <Divider />

                            <Heading size="sm">Update Interval</Heading>

                            <HStack alignItems="center" space={3}>
                              <Text>Take a reading every</Text>
                              <Input
                                onChangeText={handleChange('sensorUpdateInterval')}
                                onBlur={handleBlur('sensorUpdateInterval')}
                                value={values.sensorUpdateInterval}
                              />
                              <Text>hours</Text>
                            </HStack>

                          </>

                        )}

                      </>
                    )}

                    {notificationTypeOptions.length >= 1 && values.sensorType !== '' ? (
                      <VStack space="3">
                        <Divider />
                        {values.sensorIgnoring ? null : (
                          <>
                            <Heading size="sm">Alerts</Heading>
                            <FormControl>
                              <Checkbox.Group
                                colorScheme="gray"
                                defaultValue={values.sensorNotifyType}
                                accessibilityLabel="choose multiple items"
                                onChange={(notificationCheckboxValues) => {
                                  setFieldValue(
                                    'sensorNotifyType', notificationCheckboxValues, false
                                  );
                                }}
                                alignItems="flex-start"
                              >
                                {notificationTypeOptions.map((item, index) => (
                                  <div key={index}>
                                    {item.value === 'high' ? (
                                      <HStack alignItems="center" space={3} my="1">
                                        <Checkbox value={item.value} key={"check-"+item.value}>
                                          <Text ml="2">Above</Text>
                                        </Checkbox>
                                        <Input
                                          key={"input"+item.value}
                                          keyboardType="numeric"
                                          onChangeText={handleChange('sensorUpperLimit')}
                                          onBlur={handleBlur('sensorUpperLimit')}
                                          value={values.sensorUpperLimit}
                                        />
                                        <Text key={"text-"+item.value}>{SensorTypes[values.sensorType]?.readingValue1Unit}</Text>
                                      </HStack>
                                    ) : null}
                                    {item.value === 'low' ? (
                                      <HStack alignItems="center" space={3} my="1">
                                        <Checkbox value={item.value} key={"check-"+item.value}>
                                          <Text ml="2">Below</Text>
                                        </Checkbox>
                                        <Input
                                          key={"input"+item.value}
                                          keyboardType="numeric"
                                          onChangeText={handleChange('sensorLowerLimit')}
                                          onBlur={handleBlur('sensorLowerLimit')}
                                          value={values.sensorLowerLimit}
                                        />
                                        <Text key={"text-"+item.value}>{SensorTypes[values.sensorType]?.readingValue1Unit}</Text>
                                      </HStack>
                                    ) : null}
                                    {item.value === 'rate' ? (
                                      <>
                                        <HStack alignItems="center" space={3} my="1">
                                          <Checkbox value={item.value} key={"check-"+item.value}>
                                            <Text ml="2">Level falls by more than</Text>
                                          </Checkbox>
                                        </HStack>
                                        <HStack paddingLeft={8}>  
                                            <Input
                                              key={"input"+item.value}
                                              keyboardType="numeric"
                                              onChangeText={handleChange('sensorTankWarnOnPercentageDrop')}
                                              onBlur={handleBlur('sensorTankWarnOnPercentageDrop')}
                                              value={values.sensorTankWarnOnPercentageDrop}
                                            />
                                            <Text ml="2">% from last reading</Text>                                        
                                        </HStack>
                                      </>
                                    ) : null}
                                    {item.value !== 'high' && item.value !== 'low' && item.value !== 'rate' ? (
                                      <Checkbox value={item.value} my="1" key={"check-"+item.value}>
                                        {item.label}
                                      </Checkbox>
                                    ) : null}
                                  </div>
                                ))}
                              </Checkbox.Group>
                            </FormControl>

                            <Heading size="sm">Notifications</Heading>

                            <FormControl>
                              <Checkbox.Group
                                colorScheme="gray"
                                defaultValue={values.sensorNotifyMethod}
                                accessibilityLabel="Choose multiple items"
                                onChange={(notifyMethodCheckboxValues) => {
                                  setFieldValue(
                                    'sensorNotifyMethod', notifyMethodCheckboxValues, false
                                  );
                                }}
                                alignItems="flex-start"
                              >
                                {notificationMethodOptions.map((item, index) => (
                                  <Checkbox value={item.value} my="1" key={item.value}>{item.label}</Checkbox>
                                ))}
                              </Checkbox.Group>
                            </FormControl>
                            <Divider />

                          </>

                        )}

                      </VStack>
                    ) : (
                      null
                    )}

                    {values.sensorType !== '' ? (
                      <Checkbox
                        onChange={() => {
                          setFieldValue('sensorIgnoring', !values.sensorIgnoring);
                        }}
                        isChecked={values.sensorIgnoring}
                      >
                        Ignore Sensor
                      </Checkbox>
                    ) : null}

                  </VStack>

                </Modal.Body>

                <Modal.Footer>
                  <HStack space={2} style={{ width: "100%", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>

                    {confirmDelete ? (
                      <Button variant="ghost" onPress={() => setConfirmDelete(false)} >Cancel Delete</Button>
                    ) : (
                      <>
                        {userFarmAccessLevel >= 3 ? (
                          <Button colorScheme="danger" onPress={() => setConfirmDelete(true)}>Delete</Button>
                        ) : <Button.Group></Button.Group> }
                      </>
                    )}

                    {!confirmDelete ? (
                      <Button.Group space={2}>
                        <Button variant="ghost" onPress={() => { setEditingSensor(false); setConfirmDelete(false); }}>Cancel</Button>
                        <Button onPress={() => handleSubmit()}>Save</Button>
                      </Button.Group>
                    ) : <Button colorScheme="danger" onPress={() => onDeleteSensorHandler()}>Confirm Delete</Button>}

                  </HStack>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
      
    </ScreenWrapper>
  );
};

export default SensorHistoryScreen;
