import 'react-native-gesture-handler';
import React  from "react";
import { useFirebaseApp, FirestoreProvider, AuthProvider } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import AppNavigator from '../navigation/AppNavigator';

export default function authWrapper() {

  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);

  const firestoreInstance = getFirestore(firebaseApp);

  // TODO: reactfire- enable offline etc.

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AppNavigator />
      </FirestoreProvider>
    </AuthProvider>
  )
}
