const SensorTypes = {
  relay: {
    label: 'Relay',
    labelPlural: 'Relays',
    enableGraph: false,
    notificationTypes: false,
  },
  tank: {
    label: 'Water Tank',
    labelPlural: 'Water Tanks',
    enableGraph: true,
    readingValue1Unit: 'mm',
    readingValue1Name: 'Water Level',
    displayOptions: {
      millimeters: {
        label: 'Millimeters',
        value: 'mm'
      },
      litres: {
        label: 'Litres',
        value: 'L'
      },
      percentage: {
        label: 'Percentage',
        value: '%'
      }
    },
    notificationTypes: ['high', 'low', 'rate'],
  },
  trough: {
    label: 'Trough Level',
    labelPlural: 'Troughs',
    enableGraph: true,
    readingValue1Unit: 'mm',
    readingValue1Name: 'Water Level',
    notificationTypes: ['high', 'low'],
  },
  dam: {
    label: 'Dam',
    labelPlural: 'Dams',
    enableGraph: true,
    readingValue1Unit: 'mm',
    readingValue1Name: 'Water Level',
    notificationTypes: false,
  },
  soil: {
    label: 'Soil Temp.',
    labelPlural: 'Soil Sensors',
    enableGraph: true,
    readingValue1Unit: '°',
    readingValue1Name: 'Soil Temperature',
    notificationTypes: ['high', 'low'],
  },
  fridge: {
    label: 'Fridge Temp.',
    labelPlural: 'Fridges',
    enableGraph: true,
    readingValue1Unit: '°',
    readingValue1Name: 'Fridge Temperature',
    notificationTypes: ['high', 'low'],
  },
  gate: {
    label: 'Gate Sensor',
    labelPlural: 'Gates',
    enableGraph: false,
    readingValue1Unit: '',
    readingValue1Name: 'Gate State',
    notificationTypes: ['open', 'close'],
  },
  rain: {
    label: 'Rainfall',
    labelPlural: 'Rain Gauges',
    enableGraph: true,
    readingValue1Unit: 'mm',
    readingValue1Name: 'Rain Amount',
    notificationTypes: false,
  },
  fuel: {
    label: 'Fuel Tank',
    labelPlural: 'Fuel Tanks',
    enableGraph: true,
    readingValue1Unit: 'mm',
    readingValue1Name: 'Fuel Level',
    fuelTypes: {
      petrol: {
        label: 'Petrol',
        density: 1
      },
      diesel: {
        label: 'Diesel',
        density: 1.1
      },
      other: {
        label: 'Other'
      }
    },
    displayOptions: {
      millimeters: {
        label: 'Millimeters',
        value: 'mm'
      },
      litres: {
        label: 'Litres',
        value: 'L'
      },
      percentage: {
        label: 'Percentage',
        value: '%'
      }
    },
    notificationTypes: ['high', 'low', 'rate'],
  }
};
export default SensorTypes;
